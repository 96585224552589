import { createRouter, createWebHistory } from 'vue-router'
// @ts-expect-error-next-line
import webappStatTrack from 'webapp-stats'

import LoginPage from '@/modules/CORE/auth/LoginPage.vue'
import LogoutPage from '@/modules/CORE/auth/LogoutPage.vue'

import type { RouteCrumb } from '@/modules/CORE/interfaces/CoreTypes'

import { i18n } from '@/i18n.js'

// @ts-expect-error-next-line
const t = i18n.global.t

declare module 'vue-router' {
    interface RouteMeta {
        menuContext?: string
        breadcrumbs?: RouteCrumb[]
    }
}

const baseCrumbs: RouteCrumb[] = [
    { fixedName: t('common.home'), route: { name: 'home' } },
    { dynamicName: 'CURRENT_COMPANY', route: { name: 'company-facilities' } },
]

const baseCrumbsAdminMain: RouteCrumb[] = [...baseCrumbs, { fixedName: t('common.admin'), route: '' }]

const baseCrumbsAdminSub: RouteCrumb[] = [...baseCrumbs, { fixedName: t('common.admin'), route: { name: 'company-admin' } }]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        // *** "NO CONTEXT" ROUTES *** //
        // i.e. routes that are not tied to a specific company or facility
        // hence there is no companyId or facilityId parameter in the currentRoute object

        // * CORE & AUTH
        {
            path: '/login',
            name: 'login-page',
            component: LoginPage,
            meta: { menuContext: 'login' },
        },
        {
            path: '/logout',
            name: 'logout-page',
            component: LogoutPage,
            meta: { menuContext: 'logout' },
        },
        {
            path: '/',
            redirect: '/login',
            meta: { menuContext: 'login' },
        },
        {
            path: '/accept-invitation/:token',
            name: '/accept-invitation',
            component: () => import('@/modules/CORE/user/AcceptInvite.vue'),
        },
        { path: '/reset-password/:token/:uid', name: 'reset-password', component: () => import('@/modules/CORE/user/ResetPassword.vue') },
        {
            path: '/home',
            name: 'home',
            component: () => import('@/modules/CORE/home/HomePage.vue'),
            meta: {
                menuContext: 'home',
                breadcrumbs: [{ fixedName: t('common.home'), route: '' }],
            },
        },
        {
            // *** COMPANY CONTEXT ROUTES *** //
            // i.e. routes that has `:companyId` in the path, hence there's a companyId parameter in the currentRoute object

            // * COMPANY
            path: '/admin',
            name: 'admin',
            children: [
                {
                    name: 'company-admin',
                    path: '/company/:companyId/admin',
                    component: () => import('@/modules/CORE/admin/AdminPage.vue'),
                    meta: {
                        menuContext: 'company-admin',
                        breadcrumbs: baseCrumbsAdminMain,
                    },
                },
                {
                    name: 'pause-work-order-generation',
                    path: '/company/:companyId/pause-work-order-generation',
                    component: () => import('@/modules/CORE/admin/PauseWorkOrderGeneration.vue'),
                    meta: {
                        menuContext: 'company-admin',
                        breadcrumbs: [...baseCrumbsAdminSub, { fixedName: t('admin.pause-work-order-generation'), route: '' }],
                    },
                },
                {
                    path: '/company/:companyId/facilities/create',
                    name: 'facility-create',
                    component: () => import('@/modules/CORE/admin/FacilityCreate.vue'),
                    meta: {
                        menuContext: 'company-admin',
                        breadcrumbs: [...baseCrumbsAdminSub, { fixedName: t('facility.create'), route: '' }],
                    },
                },
                {
                    path: '/company/:companyId/users',
                    name: 'company-users',
                    component: () => import('@/modules/CORE/admin/CompanyUsers.vue'),
                    meta: {
                        menuContext: 'company-admin',
                        breadcrumbs: [...baseCrumbsAdminSub, { fixedName: t('admin.company-user-management'), route: '' }],
                    },
                },
                {
                    path: 'company/upload-multiple-documents',
                    name: 'company-upload-multiple-documents',
                    component: () => import('@/modules/SUPERUSER/UploadMultipleDocuments.vue'),
                    meta: {
                        menuContext: 'company-admin',
                        breadcrumbs: [...baseCrumbsAdminSub, { fixedName: t('admin.upload-multiple-documents'), route: '' }],
                    },
                },
                {
                    path: '/company/:companyId/equipment-bulk-edit',
                    name: 'equipment-bulk-edit',
                    component: () => import('@/modules/CORE/EquipmentBulkEdit.vue'),
                    meta: {
                        menuContext: 'admin',
                        breadcrumbs: [...baseCrumbsAdminSub, { fixedName: 'Equipment Bulk Edit', route: '' }],
                    },
                },
            ],
        },
        {
            path: '/company/:companyId/edit',
            name: 'company-edit',
            component: () => import('@/modules/CORE/company/CompanyEdit.vue'),
            meta: {
                menuContext: 'company',
                breadcrumbs: [
                    { fixedName: t('common.home'), route: { name: 'home' } },
                    { dynamicName: 'CURRENT_COMPANY', route: { name: 'company-facilities' } },
                    { fixedName: t('common.edit'), route: '' },
                ],
            },
        },
        // * DASHBOARD
        {
            path: '/company/:companyId/dashboard',
            name: 'dashboard',
            component: () => import('@/modules/CONNECT/dashboard/DashboardPage.vue'),
            meta: {
                menuContext: 'dashboard',
                breadcrumbs: [...baseCrumbs, { fixedName: 'Dashboard', route: '' }],
            },
        },
        // * MAP
        {
            path: '/company/:companyId/map',
            name: 'company-map',
            component: () => import('@/modules/MAP/MapPage.vue'),
            meta: {
                menuContext: 'map',
                breadcrumbs: [...baseCrumbs, { fixedName: t('common.map'), route: '' }],
            },
        },
        // * REMARKS FOR SUBCONTRACTORS
        {
            path: '/company/:companyId/subcontractor-remarks',
            name: 'subcontractor-remarks',
            component: () => import('@/modules/YARD/remarks/SubcontractorRemarks.vue'),
            meta: {
                menuContext: 'subcontractor-remarks',
                breadcrumbs: [...baseCrumbs, { fixedName: 'Remarks', route: '' }],
            },
        },
        {
            path: '/company/:companyId/subcontractor-remarks-create',
            name: 'subcontractor-remarks-create',
            component: () => import('@/modules/YARD/remarks/SubcontractorRemarksCreate.vue'),
            meta: {
                menuContext: 'subcontractor-remarks',
                breadcrumbs: [
                    ...baseCrumbs,
                    { fixedName: 'Remarks', route: { name: 'subcontractor-remarks' } },
                    { fixedName: 'Create remark', route: '' },
                ],
            },
        },

        // * DATA EXPLORER
        {
            path: '/company/:companyId/data-explorer',
            name: 'data-explorer',
            component: () => import('@/modules/CORE/dataexplorer/DataExplorer.vue'),
            meta: {
                menuContext: 'data-explorer',
                breadcrumbs: [...baseCrumbs, { fixedName: 'Data explorer', route: '' }],
            },
        },
        // * NOTIFICATIONS
        {
            path: '/company/:companyId/notifications',
            name: 'company-notifications',
            component: () => import('@/modules/CORE/notifications/NotificationPage.vue'),
            meta: {
                menuContext: 'notifications',
                breadcrumbs: [...baseCrumbs, { fixedName: t('side_nav.notifications'), route: '' }],
            },
        },
        {
            path: '/company/:companyId/notifications/?facilityId=:facilityId',
            name: 'facility-notifications',
            component: () => import('@/modules/CORE/notifications/NotificationPage.vue'),
            meta: {
                menuContext: 'notifications',
                breadcrumbs: [...baseCrumbs, { fixedName: t('side_nav.notifications'), route: '' }],
            },
        },

        {
            path: '/company/:companyId/facilities',
            name: 'company-facilities',
            component: () => import('@/modules/CORE/company/CompanyFacilities.vue'),
            meta: {
                menuContext: 'company',
                breadcrumbs: [...baseCrumbs, { fixedName: t('common.company-overview'), route: '' }],
            },
        },
        // * FACILITY
        {
            path: '/facility/:facilityId',
            name: 'facility-detail',
            component: () => import('@/modules/CORE/facility/FacilityPage.vue'),
            meta: {
                menuContext: 'facility',
                breadcrumbs: [...baseCrumbs, { dynamicName: 'CURRENT_FACILITY', route: '' }],
            },
        },

        // * CHECKLISTS
        {
            path: '/facility/:facilityId/checklists/create',
            name: 'checklist-create',
            component: () => import('@/modules/MAINTENANCE/checklist/ChecklistCreate.vue'),
            meta: {
                menuContext: 'checklist',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('checklist.checklists'), route: { name: 'checklist-page' } },
                    { fixedName: t('common.create'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/checklists',
            name: 'checklist-page',
            component: () => import('@/modules/MAINTENANCE/checklist/ChecklistPage.vue'),
            meta: {
                menuContext: 'checklist',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('checklist.checklists'), route: '' },
                ],
            },
        },

        // * DOCUMENTS
        {
            path: '/facility/:facilityId/documents',
            name: 'document-list',
            component: () => import('@/modules/MAINTENANCE/documents/DocumentPage.vue'),
            meta: {
                menuContext: 'document',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('side_nav.documents'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/document-create',
            name: 'document-create',
            component: () => import('@/modules/MAINTENANCE/documents/DocumentCreate.vue'),
            meta: {
                menuContext: 'document',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('side_nav.documents'), route: { name: 'document-list' } },
                    { fixedName: t('document.add-document'), route: '' },
                ],
            },
        },

        // * EQUIPMENT
        {
            path: '/facility/:facilityId/equipment-create',
            name: 'equipment-create',
            component: () => import('@/modules/MAINTENANCE/equipment/EquipmentCreate.vue'),
            meta: {
                menuContext: 'equipment',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('common.equipment'), route: { name: 'equipment-list' } },
                    { fixedName: t('common.create'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/equipment',
            name: 'equipment-list',
            component: () => import('@/modules/MAINTENANCE/equipment/EquipmentPage.vue'),
            meta: {
                menuContext: 'equipment',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('common.equipment'), route: '' },
                ],
            },
        },
        // * REMARKS
        {
            path: '/facility/:facilityId/remarks',
            name: 'remarks-page',
            component: () => import('@/modules/YARD/remarks/RemarksPage.vue'),
            meta: {
                menuContext: 'remarks',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('remarks.remarks'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/remarks/create',
            name: 'remarks-create',
            component: () => import('@/modules/YARD/remarks/RemarksCreate.vue'),
            meta: {
                menuContext: 'remarks',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('remarks.remarks'), route: { name: 'remarks-page' } },
                    { fixedName: t('remarks.create-remark'), route: '' },
                ],
            },
        },
        // * WORK ORDER
        {
            path: '/facility/:facilityId/workorders',
            name: 'workorder-page',
            component: () => import('@/modules/MAINTENANCE/workorders/WorkorderPage.vue'),
            meta: {
                menuContext: 'workorder',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('workorder.workorder'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/workorders/create',
            name: 'workorder-create-page',
            component: () => import('@/modules/MAINTENANCE/workorders/WorkorderCreatePage.vue'),
            meta: {
                menuContext: 'workorder',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('workorder.workorder'), route: { name: 'workorder-page' } },
                    { fixedName: t('common.create'), route: '' },
                ],
            },
        },
        // * SUPPORT
        {
            path: '/facility/:facilityId/support',
            name: 'support-page',
            component: () => import('@/modules/SUPPORT/SupportPage.vue'),
            meta: {
                menuContext: 'support',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('side_nav.support'), route: '' },
                ],
            },
        },
        {
            path: '/facility/:facilityId/support/create',
            name: 'create-claim',
            component: () => import('@/modules/SUPPORT/SupportCaseCreate.vue'),
            meta: {
                menuContext: 'support',
                breadcrumbs: [
                    { fixedName: t('common.facilities'), route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: t('side_nav.support'), route: { name: 'support-page' } },
                    { fixedName: t('common.create'), route: '' },
                ],
            },
        },
        // * CONNECT
        {
            path: '/facility/:facilityId/connect-dashboard',
            name: 'connect-dashboard',
            component: () => import('@/modules/CONNECT/dashboard/ConnectDashboard.vue'),
            meta: {
                menuContext: 'company-facilities',
                breadcrumbs: [
                    { dynamicName: 'CURRENT_COMPANY', route: { name: 'company-facilities' } },
                    { dynamicName: 'CURRENT_FACILITY', route: { name: 'facility-detail' } },
                    { fixedName: 'Connect Dashbord', route: '' },
                ],
            },
        },
        // * USER PAGES
        {
            path: '/user',
            name: 'user',
            children: [
                {
                    path: '/user/user-settings',
                    name: 'user-settings',
                    component: () => import('@/modules/CORE/user/UserSettings.vue'),
                    meta: {
                        menuContext: 'user',
                        breadcrumbs: [
                            { fixedName: t('common.home'), route: { name: 'home' } },
                            { fixedName: t('user.settings'), route: '' },
                        ],
                    },
                },
                {
                    path: '/user/change-password',
                    name: 'change-password',
                    component: () => import('@/modules/CORE/user/ChangePassword.vue'),
                    meta: {
                        menuContext: 'user',
                        breadcrumbs: [
                            { fixedName: t('common.home'), route: { name: 'home' } },
                            { fixedName: t('user.change_password'), route: '' },
                        ],
                    },
                },
                {
                    path: '/user/change-language',
                    name: 'change-language',
                    component: () => import('@/modules/CORE/user/ChangeLanguage.vue'),
                    meta: {
                        menuContext: 'user',
                        breadcrumbs: [
                            { fixedName: t('common.home'), route: { name: 'home' } },
                            { fixedName: t('user.change_language'), route: '' },
                        ],
                    },
                },
                {
                    path: '/user/personal-user-settings',
                    name: 'personal-user-settings',
                    component: () => import('@/modules/CORE/user/PersonalUserSettings.vue'),
                    meta: {
                        menuContext: 'user',
                        breadcrumbs: [
                            { fixedName: t('common.home'), route: { name: 'home' } },
                            { fixedName: t('user.settings'), route: '' },
                        ],
                    },
                },
            ],
        },

        // * SUPERUSER PAGES
        {
            path: '/superuser',
            name: 'superuser',
            children: [
                {
                    path: '/superuser/options',
                    name: 'superuser-page',
                    component: () => import('@/modules/SUPERUSER/SuperuserPage.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [{ fixedName: 'Superadmin', route: '' }],
                    },
                },
                {
                    path: '/superuser/user-management',
                    name: 'superuser-user-management',
                    component: () => import('@/modules/SUPERUSER/UserManagement.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('superuser.user-management'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/user-management/create-user',
                    name: 'superuser-create-user',
                    component: () => import('@/components/user/UserCreate.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('superuser.user-management'), route: { name: 'superuser-user-management' } },
                            { fixedName: t('common.create'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/company-create',
                    name: 'superuser-company-create',
                    component: () => import('@/modules/SUPERUSER/CompanyCreate.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('company.create'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/company-management',
                    name: 'superuser-company-management',
                    component: () => import('@/modules/SUPERUSER/CompanyManagement.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('superuser.company-management'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/facility-admin',
                    name: 'facility-admin',
                    component: () => import('@/modules/SUPERUSER/FacilityAdmin.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('facility.admin'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/upload-multiple-documents',
                    name: 'superuser-upload-multiple-documents',
                    component: () => import('@/modules/SUPERUSER/UploadMultipleDocuments.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('admin.upload-multiple-documents'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/e-dashboard',
                    name: 'superuser-ag-grid-edashboard-ettermarked',
                    component: () => import('@/modules/SUPERUSER/EDashboardEttermarked.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'E-Dashboard', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/monday-integration-admin',
                    name: 'superuser-monday-integration-admin',
                    component: () => import('@/modules/SUPERUSER/SupportBackoffice.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('superuser.monday-board-integration'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-base',
                    name: 'superuser-ag-grid-base',
                    component: () => import('@/modules/SUPERUSER/AGGridBase.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Companies & Facilities (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-remarks',
                    name: 'superuser-ag-grid-remarks',
                    component: () => import('@/modules/SUPERUSER/AGGridRemarks.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Remarks', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-classifications',
                    name: 'superuser-ag-grid-classifications',
                    component: () => import('@/modules/SUPERUSER/AGGridClassifications.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Moen Classification System', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-users',
                    name: 'superuser-ag-grid-users',
                    component: () => import('@/modules/SUPERUSER/AGGridUsers.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Users', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-equipment',
                    name: 'superuser-ag-grid-equipment',
                    component: () => import('@/modules/SUPERUSER/AGGridEquipment.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Equipmentdatabase (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-charts-claims-status',
                    name: 'superuser-ag-charts-claims-status',
                    component: () => import('@/modules/SUPERUSER/AGChartsClaimsStatus.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Claims status (AG Charts)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-claims-status',
                    name: 'superuser-ag-grid-claims-status',
                    component: () => import('@/modules/SUPERUSER/AGGridClaimsStatus.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Claims status (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-facility-connect-sensors',
                    name: 'superuser-ag-grid-facility-connect-sensors',
                    component: () => import('@/modules/SUPERUSER/AGGridFacilityConnectSensors.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Backoffice', route: { name: 'superuser-page' } },
                            { fixedName: 'Facility Connect Sensors (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-connect-status',
                    name: 'superuser-ag-grid-connect-status',
                    component: () => import('@/modules/SUPERUSER/AGGridConnectStatus.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Connect Status (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/ag-grid-connect-sensors',
                    name: 'superuser-ag-grid-connect-sensors',
                    component: () => import('@/modules/SUPERUSER/AGGridConnectSensors.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Connect Sensors (AG Grid)', route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/webapp-stats',
                    name: 'superuser-webapp-stats',
                    component: () => import('@/modules/SUPERUSER/WebappStats.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: t('superuser.usage-data'), route: '' },
                        ],
                    },
                },
                {
                    path: '/superuser/template-page',
                    name: 'superuser-template-page',
                    component: () => import('@/modules/SUPERUSER/TemplatePage.vue'),
                    meta: {
                        menuContext: 'superuser',
                        breadcrumbs: [
                            { fixedName: 'Superadmin', route: { name: 'superuser-page' } },
                            { fixedName: 'Template page', route: '' },
                        ],
                    },
                },
            ],
        },

        // UTILS
        {
            path: '/:catchAll(.*)',
            name: 'not-found',
            component: () => import('@/components/generic/NotFound.vue'),
        },
        {
            path: '/not-allowed',
            name: 'not-allowed',
            component: () => import('@/components/generic/NotAllowed.vue'),
        },
        {
            path: '/no-maintenance-module',
            name: 'no-maintenance-module',
            component: () => import('@/components/generic/NoMaintenanceModule.vue'),
        },
    ],
})

// webapp-stats config
const appId = 'd0137ecd-c71c-4597-82e4-0cae5886fdc7' // mlink-prod-v4 app_id
const urlPrefix = 'https://europe-west3-mlink-prod.cloudfunctions.net'
const bufferStack: number = 5 // after each x route navigations the stats will be sent to the server

// router middleware (webapp-stats)
router.beforeEach((to, from, next) => {
    // for production environment only
    if (window.location.origin.includes('https://v4.m-link.no')) {
        const username = localStorage.getItem('mlink-username')
        webappStatTrack(to, from, appId, urlPrefix, bufferStack, username)
    }

    next()
})

export default router

<template>
    <div>
        <div id="mobile-head">
            <div id="mobile-logo" role="button" @click="goToRoute('home')">
                <MenuLogo />
            </div>
            <div id="mobile-nav-main-buttons">
                <!-- Notifications button (icon + text) -->
                <div class="mobile-nav-main-button" role="button" @click="navIconButtonClick('notifications')">
                    <div class="mobile-nav-main-button-icon">
                        <MCIcon
                            icon="notifications"
                            :color="hasUnreadNotifications ? '#ee0000' : '#777'"
                            :class="{ 'bell-wiggle': isBellWiggling }"
                        />

                        <div v-if="showNotifications" v-click-outside="closeNotificationMenu" class="mobile-notification-cards">
                            <div style="display: flex; justify-content: right; align-items: right">
                                <MenuButton
                                    id="notification-settings-button"
                                    :text="t('side_nav.notifications')"
                                    icon="settings"
                                    style=""
                                    @action="
                                        () => {
                                            router.push({ name: 'personal-user-settings', query: { page: 'notifications-settings' } })
                                        }
                                    "
                                />
                            </div>
                            <NotificationCard
                                v-for="notification in store.notifications"
                                :key="notification.id"
                                :notification="notification"
                                :tabindex="0"
                            />
                        </div>

                        <MobileNavIconClear v-else />
                    </div>
                </div>

                <!-- Search button (icon + text) -->
                <div class="mobile-nav-main-button" role="button" @click="navIconButtonClick('search')">
                    <div class="mobile-nav-main-button-icon">
                        <MCIcon v-if="!showMobileSearch" icon="search" />
                        <MobileNavIconClear v-else />
                    </div>
                </div>

                <!-- Hamburger button (icon + text) -->
                <div class="mobile-nav-main-button" role="button" @click="navIconButtonClick('menu')">
                    <div class="mobile-nav-main-button-icon">
                        <MobileNavIconBurger v-if="!showMobileMenu" />
                        <MobileNavIconClear v-else />
                    </div>
                </div>
            </div>
        </div>

        <!-- Search menu overlay -->
        <div v-if="showMobileSearch" id="mobile-menu-overlay">
            <GlobalSearch @escapeClicked="showMobileSearch = false" @searchResultClicked="showMobileSearch = false" />
        </div>
        <!-- Main menu overlay -->
        <div v-if="showMobileMenu" id="mobile-menu-overlay">
            <div id="mobile-menu-overlay-container">
                <TransitionGroup :name="slideAnimationMode" tag="div" class="mobile-menu-overlay-section" mode="out-in">
                    <!-- First slide -->
                    <div v-if="showStandardMenu" key="general" class="mobile-menu-overlay-menu-items">
                        <!-- * Superuser * -->
                        <div
                            v-if="store.currentUser?.is_superuser"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('superuser-page')"
                        >
                            <MCIcon icon="superuser" />
                            <p class="body-body">
                                {{ t('side_nav.superuser') }}
                            </p>
                        </div>
                        <div
                            v-if="store.userIsAdminOrSuperUser && store.currentCompany"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('admin')"
                        >
                            <MCIcon icon="settings" />
                            <p class="body-body">
                                {{ t('common.admin') }}
                            </p>
                        </div>

                        <!-- Home -->
                        <div class="mobile-menu-overlay-menu-item" @click="goToRoute('home')">
                            <MCIcon icon="home" />
                            <p class="body-body">
                                {{ t('common.home') }}
                            </p>
                        </div>

                        <!-- All boats -->
                        <div
                            v-if="!store.currentCompany?.is_subcontractor"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('company-facilities')"
                        >
                            <MCIcon icon="directions-boat" />
                            <p class="body-body">All facilities for {{ store.currentCompany?.name }}</p>
                        </div>

                        <!-- Company/Fleet Dashboard -->
                        <div
                            v-if="!store.currentCompany?.is_subcontractor"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('dashboard')"
                        >
                            <MCIcon icon="dashboard" />
                            <p class="body-body">
                                {{ t('company.features.enable_dashboard.label') }}
                            </p>
                        </div>

                        <!-- Data explorer -->
                        <div
                            v-if="!store.currentCompany?.is_subcontractor"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('data-explorer')"
                        >
                            <MCIcon icon="dashboard-filled" />
                            <p class="body-body">
                                {{ t('side_nav.data-explorer') }}
                            </p>
                        </div>

                        <!-- Remarks for subcontractors -->
                        <div
                            v-if="showRemarksForSubcontractors"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('subcontractor-remarks')"
                        >
                            <MCIcon icon="warning" />
                            <p class="body-body">
                                {{ t('common.remarks') }}
                            </p>
                        </div>

                        <div class="menu-divider" />
                        <p class="body-small" style="margin-left: 3.75rem; margin-top: 1rem; color: gray">
                            {{ t('common.company') }}
                        </p>

                        <!-- * Current Company * -->
                        <div class="mobile-menu-overlay-menu-item" @click="goToRoute('company')">
                            <MCIcon icon="business" />
                            <p class="body-body">
                                {{ store.currentCompany?.name }}
                            </p>
                            <MCIcon
                                v-if="userHasAccessToMultipleCompanies"
                                icon="arrow-right"
                                style="margin-left: auto"
                                @click.stop="
                                    () => {
                                        showCompanySelectorMenu = true
                                        slideAnimationMode = 'slide-left'
                                        // showFacilityMenuItems = false
                                        showStandardMenu = false
                                    }
                                "
                            />
                        </div>

                        <!-- * Company Admin * -->
                        <div
                            v-if="store.userIsAdminOrSuperUser && store.currentCompany"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('admin')"
                        >
                            <MCIcon icon="settings" />
                            <p class="body-body">
                                {{ t('common.admin') }}
                            </p>
                        </div>

                        <!-- * Data explorer (all data within company) * -->
                        <div
                            v-if="!store.currentCompany?.is_subcontractor"
                            class="mobile-menu-overlay-menu-item"
                            @click="goToRoute('data-explorer')"
                        >
                            <MCIcon icon="dashboard-filled" />
                            <p class="body-body">
                                {{ t('side_nav.data-explorer') }}
                            </p>
                        </div>

                        <!-- * Map * -->
                        <div v-if="!store.currentCompany?.is_subcontractor" class="mobile-menu-overlay-menu-item" @click="goToRoute('map')">
                            <MCIcon icon="map" />
                            <p class="body-body">
                                {{ t('common.map') }}
                            </p>
                        </div>

                        <!-- Facilities -->
                        <div
                            class="mobile-menu-overlay-menu-item"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-left'
                                    showFacilityMenuItems = true
                                    showStandardMenu = false
                                }
                            "
                        >
                            <MCIcon icon="directions-boat-filled" />
                            <p class="body-body">
                                {{ store.currentFacility?.name }}
                            </p>
                            <MCIcon
                                icon="arrow-right"
                                style="margin-left: auto"
                                @click.stop="
                                    () => {
                                        slideAnimationMode = 'slide-left'
                                        showFacilityMenuItems = false
                                        showStandardMenu = false
                                        showCompanyFacilityMenu = true
                                    }
                                "
                            />
                        </div>

                        <!-- User options -->
                        <div class="mobile-menu-overlay-menu-item" style="margin-top: auto" />
                        <div
                            class="mobile-menu-overlay-menu-item"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-left'
                                    showFacilityMenuItems = false
                                    showStandardMenu = false
                                    showUserMenu = true
                                }
                            "
                        >
                            <MCIcon icon="user-profile_gray-darkest" />
                            <p class="body-body">
                                {{ prettifyUserDisplayname(store.currentUser) }}
                            </p>
                            <MCIcon icon="arrow-right" style="margin-left: auto" />
                        </div>
                    </div>
                    <!-- Company selector menu -->
                    <div v-if="showCompanySelectorMenu" key="company" class="mobile-menu-overlay-section">
                        <div
                            class="mobile-menu-overlay-menu-header"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-right'
                                    showCompanySelectorMenu = false
                                    showStandardMenu = true
                                }
                            "
                        >
                            <MCIcon icon="arrow-left" />
                            <p class="menu-header" style="margin-right: auto">
                                {{ t('common.back') }}
                            </p>
                        </div>

                        <MobileMenuCompanySwitcher
                            @closeMenu="
                                (companyId) => {
                                    slideAnimationMode = 'slide-left'
                                    showCompanySelectorMenu = false
                                    showStandardMenu = true
                                    showMobileMenu = false
                                }
                            "
                        />
                    </div>

                    <!-- Fasilitet -->
                    <div v-if="showFacilityMenuItems" key="facility" class="mobile-menu-overlay-section">
                        <div
                            class="mobile-menu-overlay-menu-header"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-right'
                                    showFacilityMenuItems = false
                                    showStandardMenu = true
                                }
                            "
                        >
                            <MCIcon icon="arrow-left" />
                            <p class="menu-header" style="margin-right: auto">
                                {{ t('common.back') }}
                            </p>
                        </div>
                        <div class="mobile-menu-overlay-menu-items mobile-menu-overlay-menu-items-with-header">
                            <div class="mobile-menu-overlay-menu-item-facility">
                                <div class="facility-info-short">
                                    <div class="facility-info-short-container" @click="goToRoute('facility')">
                                        <div class="facility-info-short-image">
                                            <img
                                                v-if="store.currentFacility?.image"
                                                :src="getFacilityImageUrl(store.currentFacility?.image)"
                                                @error="handleError()"
                                            />
                                        </div>
                                        <div class="facility-info-short-info">
                                            <p>{{ store.currentFacility?.name }}</p>
                                            <p @click.stop="goToCurrentCompanyFacilities">
                                                {{ computedSubLabel }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="showRemarks" class="mobile-menu-overlay-menu-item" @click="goToRoute('remarks')">
                                <MCIcon icon="warning" />
                                <p class="body-body">
                                    {{ t('common.remarks') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('workorder')">
                                <MCIcon icon="build" />
                                <p class="body-body">
                                    {{ t('side_nav.workorders') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('equipment')">
                                <MCIcon icon="equipment" />
                                <p class="body-body">
                                    {{ t('side_nav.equipment') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('document')">
                                <MCIcon icon="description" />
                                <p class="body-body">
                                    {{ t('side_nav.documents') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('checklist')">
                                <MCIcon icon="checklist-block" />
                                <p class="body-body">
                                    {{ t('side_nav.checklists') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('support')">
                                <MCIcon icon="support-agent" />
                                <p class="body-body">
                                    {{ t('side_nav.support') }}
                                </p>
                            </div>
                            <div v-if="showConnect" class="mobile-menu-overlay-menu-item" @click="goToRoute('connect-dashboard')">
                                <MCIcon icon="dashboard_gray-darkest" />
                                <p class="body-body">
                                    {{ t('side_nav.connect') }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- Company facilities -->
                    <div v-if="showCompanyFacilityMenu" key="company" class="mobile-menu-company-facilities">
                        <div
                            class="mobile-menu-overlay-menu-header"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-right'
                                    showFacilityMenuItems = false
                                    showStandardMenu = true
                                    showCompanyFacilityMenu = false
                                }
                            "
                        >
                            <MCIcon icon="arrow-left" />
                            <p class="menu-header" style="margin-right: auto">
                                {{ t('common.back') }}
                            </p>
                        </div>
                        <div v-if="showCompanyFacilityMenu" class="mobile-menu-overlay-menu-items">
                            <template v-for="region in facilitiesByRegion" :key="region.regionId">
                                <p v-if="region.facilities.length > 0" class="region-name-paragraph">
                                    {{ region.regionName }}
                                </p>
                                <div v-if="region.facilities.length > 0" class="mobile-menu-overlay-menu-item-facility-list-item">
                                    <div v-for="facility in region.facilities" :key="facility.facilityId" class="facility-info-short">
                                        <div class="facility-info-short-container" @click="goToRoute('facility')">
                                            <div class="facility-info-short-image">
                                                <img :src="getFacilityImageUrl(facility?.image)" @error="handleError()" />
                                            </div>
                                            <div class="facility-info-short-info">
                                                <p class="bold-1">
                                                    {{ facility.facilityName }}
                                                </p>
                                                <p class="info-1">
                                                    {{ facility.localityName ? facility.localityName : '' }}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                    <!-- User settings -->
                    <div v-if="showUserMenu" key="usersettings" class="mobile-menu-overlay-section">
                        <div
                            class="mobile-menu-overlay-menu-header"
                            @click="
                                () => {
                                    slideAnimationMode = 'slide-right'
                                    showFacilityMenuItems = false
                                    showStandardMenu = true
                                    showUserMenu = false
                                }
                            "
                        >
                            <MCIcon icon="arrow-left" />
                            <p class="menu-header" style="margin-right: auto">
                                {{ t('common.back') }}
                            </p>
                        </div>
                        <div v-if="showUserMenu" class="mobile-menu-overlay-menu-items mobile-menu-overlay-menu-items-with-header">
                            <div
                                class="mobile-menu-overlay-menu-item"
                                style="flex-direction: column; align-items: flex-start; gap: 0.25rem"
                            >
                                <p class="bold-1">
                                    {{ prettifyUserDisplayname(store.currentUser) }}
                                </p>
                                <p class="info-1">
                                    {{ store.currentUser?.email }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('user-settings')">
                                <MCIcon icon="settings" />
                                <p class="body-body">
                                    {{ t('user.manage_account') }}
                                </p>
                            </div>
                            <div class="mobile-menu-overlay-menu-item" @click="goToRoute('logout-page')">
                                <MCIcon icon="logout" />
                                <p class="body-body">
                                    {{ t('side_nav.log_out') }}
                                </p>
                            </div>
                        </div>
                    </div>
                </TransitionGroup>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { MenuButton, MenuLogo, MobileMenuCompanySwitcher, MobileNavIconBurger, MobileNavIconClear } from '@/modules/CORE/navigation'
import { NotificationCard } from '@/modules/CORE/notifications/components/'
import { GlobalSearch } from '@/modules/CORE/search'
import { useGlobalStore } from '@/stores/global.js'
import companyApi from '@/api/companies'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ILocality } from '@/modules/CORE/company/interfaces/ILocality'
import type { IFacilityGroup, IRegion } from '@/modules/CORE/company/interfaces/IRegion'
import type { ICompanyFacility, ICompanyFacilityByRegion } from '@/modules/CORE/facility/interfaces/IFacility'

import { prettifyUserDisplayname } from '@/utils'

// Animation for slides
const slideAnimationMode = ref<string>('slide-left')

// store & router & translation
const { t } = useI18n()
const store = useGlobalStore()
const router = useRouter()

const showMobileProfile = ref<boolean>(false)
const showMobileSearch = ref<boolean>(false)
const showMobileMenu = ref<boolean>(false)
const showStandardMenu = ref<boolean>(true)
const showFacilityMenuItems = ref<boolean>(false)
const showCompanySelectorMenu = ref<boolean>(false)
const showCompanyFacilityMenu = ref<boolean>(false)

const showNotifications = ref(false)

const hasUnreadNotifications = computed(() => store.unseenNotificationsCount > 0)
const notificationCount = computed(() => {
    return store.unseenNotificationsCount
})

const closeNotificationMenu = () => {
    showNotifications.value = false
}

const showRemarks = computed(() => {
    if (store.currentUser?.is_superuser) return true // project managers
    if (store.currentCompany?.enable_yard) return true // inspectors
    if (store.currentCompany?.is_subcontractor) return false
    return false
})

const userHasAccessToMultipleCompanies = computed(() => {
    if (store.companyList) {
        return store.companyList.length > 1
    }
    return false
})

const isBellWiggling = ref(false)

const toggleBellWiggle = () => {
    // only show the bell wiggle if there are unread notifications and the notifications are not shown
    // isBellWiggling.value = hasUnreadNotifications.value && !showNotifications.value
    // setTimeout(() => (isBellWiggling.value = false), 500)
}

const populateCompanyList = async () => {
    store.companyList = await store.fetchCompaniesByUser()
}

onMounted(async () => {
    await store.waitForCurrentCompany()
    await fetchCompanyFacilitiesListRaw()
    populateCompanyList()
})

const useOriginalInsteadOfSmall = ref<boolean>(false)
const getFacilityImageUrl = (img: any): string => {
    if (img?.image_small) {
        return img.image_small
    } else if (img?.image) {
        return img.image
    } else {
        return '/facility_img_placeholder.png'
    }
}

const isSubcontractor = computed(() => {
    const company = store.currentCompany
    return company?.is_subcontractor
})

const showRemarksForSubcontractors = computed(() => {
    // There are some "hardcoded" manufacturers that are subcontractors,
    // This list must match the hardcoded UUID's in the API endpoint v4/subcontractors
    const hardcodedSubcontractors = [
        // '3230fd56-fd25-11e7-ace5-42010af00d40', // 'Moen Marin AS' (not shown in the menu, waiting for "Rollestyring")
        '299bf7ec-726b-4177-8f76-19f8b82c7173', // 'Mekon AS' (Should be shown in the menu as per now, before "Rollestyring")
    ]

    if (store.currentCompany) {
        return hardcodedSubcontractors.includes(store.currentCompany.id) || isSubcontractor.value
    }

    // show remarks for subcontractors
    return isSubcontractor.value
})

const handleError = () => {
    useOriginalInsteadOfSmall.value = true
}
const computedSubLabel = computed(() => {
    const currentLocalityId = store.currentFacility?.locality
    const currentRegionId = store.currentFacility?.region

    // Find the locality within the regions
    let localityName = ''
    let regionName = ''

    store.currentCompany?.regions.forEach((region: IRegion) => {
        region.localities?.forEach((locality: ILocality) => {
            if (locality.id === currentLocalityId) {
                localityName = locality.name
            }
        })

        if (region.id === currentRegionId) {
            regionName = region.name
        }
    })

    // Return the locality name if found, otherwise return the region name or company name
    return localityName || regionName || store.currentCompany?.name
})

const navIconButtonClick = (overlayMenu: 'profile' | 'search' | 'menu' | 'notifications') => {
    if (overlayMenu === 'profile') {
        showMobileProfile.value = !showMobileProfile.value
        showMobileMenu.value = false
        showMobileSearch.value = false
        showNotifications.value = false
    } else if (overlayMenu === 'menu') {
        showMobileMenu.value = !showMobileMenu.value
        showMobileProfile.value = false
        showMobileSearch.value = false
        showNotifications.value = false
    } else if (overlayMenu === 'search') {
        showMobileSearch.value = !showMobileSearch.value
        showMobileMenu.value = false
        showMobileProfile.value = false
        showNotifications.value = false
    } else if (overlayMenu === 'notifications') {
        showNotifications.value = !showNotifications.value
        showMobileMenu.value = false
        showMobileProfile.value = false
        showMobileSearch.value = false
    }
}

const goToCurrentCompanyFacilities = () => {
    router.push({ name: 'company-facilities', params: { companyId: store.currentCompany?.id } })
}

const showConnect = computed(() => {
    // showing connect requires current facility to be present
    if (!store.currentFacility) return false
    const currentFacility = store.currentFacility

    // showing connect requires connect_dashboard_enabled to be true OR user must be a superuser
    if (currentFacility.connect_dashboard_enabled || store.currentUser?.is_superuser) return true

    // else default return false
    return false
})

// router changes for main menu
const goToRoute = (route: string) => {
    if (route === 'home') {
        router.push({ path: '/' })
    } else if (route === 'facility') {
        router.push({
            name: 'facility-detail',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'support') {
        router.push({
            name: 'support-page',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'remarks') {
        router.push({
            name: 'remarks-page',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'subcontractor-remarks') {
        router.push({
            name: 'subcontractor-remarks',
            params: { companyId: store.currentCompany?.id },
        })
    } else if (route === 'workorder') {
        router.push({
            name: 'workorder-page',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'equipment') {
        router.push({
            name: 'equipment-list',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'document') {
        router.push({
            name: 'document-list',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'checklist') {
        router.push({
            name: 'checklist-page',
            params: { facilityId: store.currentFacility?.id },
        })
    } else if (route === 'admin') {
        router.push({
            name: 'company-admin',
            params: { companyId: store.currentCompany?.id },
        })
    } else if (route === 'company') {
        router.push({
            name: 'company-facilities',
            params: { companyId: store.currentCompany?.id },
        })
    } else if (route === 'map') {
        router.push({
            name: 'company-map',
            params: { companyId: store.currentCompany?.id },
        })
    } else if (route === 'dashboard') {
        router.push({
            name: 'dashboard',
            params: { companyId: store?.currentCompany?.id },
        })
    } else if (route === 'data-explorer') {
        router.push({
            name: 'data-explorer',
            params: { companyId: store?.currentCompany?.id },
        })
    } else if (route === 'user-settings') {
        router.push({
            name: 'user-settings',
        })
    } else if (route === 'logout-page') {
        router.push({
            name: 'logout-page',
        })
    } else if (route === 'superuser-page') {
        router.push({
            name: 'superuser-page',
        })
    } else if (route === 'connect-dashboard') {
        router.push({
            name: 'connect-dashboard',
            params: { facilityId: store.currentFacility?.id },
        })
    } else {
        router.push(route)
    }

    // close menu after route change
    showMobileMenu.value = false
}

const originalFacilityGroupList = ref<IFacilityGroup[]>([])
const loadingCompanyFacilitiesList = ref<boolean>(false)
const fetchCompanyFacilitiesListRaw = async (): Promise<void> => {
    loadingCompanyFacilitiesList.value = true
    await store.waitForCurrentCompany()
    if (store.currentCompany?.id) {
        originalFacilityGroupList.value = await companyApi.fetchFacilityGroups(store.currentCompany?.id as UUIDv4)
    } else {
        console.error('No current company')
    }
    loadingCompanyFacilitiesList.value = false
}

const facilitiesByRegion = computed(() => {
    const facilityByRegionList: ICompanyFacilityByRegion[] = []
    for (const facilityGroup of originalFacilityGroupList.value) {
        const companyFacilityByRegion: ICompanyFacilityByRegion = {
            regionId: facilityGroup.id as UUIDv4,
            regionName: facilityGroup.name,
            facilities: [],
        }
        for (const subGroup of facilityGroup.sub_groups) {
            const subGroupFacilities = subGroup.facilities
            if (subGroupFacilities.length > 0) {
                // loop through facilities and add "localityName" to the facility object
                for (const facility of subGroupFacilities) {
                    const obj: ICompanyFacility = {
                        companyName: facility.company_name ? facility.company_name : 'NA',
                        facilityId: facility.id ? facility.id : 'NA',
                        facilityName: facility.name ? facility.name : 'NA',
                        imageUrl: facility.image_url ? facility.image_url : '',
                        image: facility.image,
                        localityName: subGroup.name ? subGroup.name : facility.company_name,
                        maintenance_module: facility.maintenance_module,
                    }
                    companyFacilityByRegion.facilities.push(obj)
                }
            }
        }
        facilityByRegionList.push(companyFacilityByRegion)
    }

    return facilityByRegionList
})

// This bounces the notification counter to 0 after 3 seconds. And marks the notifications as seen.
// BUT, only if the notification window is open. If the user closes the notification window, then we
// assume that the users hasn't looked to closely.

// User-menu
const showUserMenu = ref<boolean>(false)
// Watcher for company-change in store
watch(
    () => store.currentCompany,
    async (_newVal, _oldVal) => {
        await fetchCompanyFacilitiesListRaw()
    },
)
</script>

<style lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/scrollbar.scss';

$mobile-menu-background-color: $standard-white;
$mobile-head-border-bottom-thickness: 0.0625rem;
$mobile-menu-top-offset: 4rem;
$mobile-menu-overlay-padding-horizontal: 0.75rem;
$mobile-menu-overlay-padding-vertical: 1.6875rem;
$mobile-menu-item-padding-vertical: 0.875rem;
$mobile-menu-border-color: $border-gray;

#mobile-head {
    // see App.vue for height & width styling
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    $mobile-head-margin-horizontal: 1.25rem;
    padding-left: $mobile-head-margin-horizontal;
    padding-right: $mobile-head-margin-horizontal;
    max-width: calc(100% - (2 * $mobile-head-margin-horizontal));
    background-color: $mobile-menu-background-color;
    // if border, we need to subtract the border height from the mobile head height
    border-bottom: $mobile-head-border-bottom-thickness solid $border-gray;
    height: calc($mobile-menu-top-offset - $mobile-head-border-bottom-thickness);
    min-height: calc($mobile-menu-top-offset - $mobile-head-border-bottom-thickness);
    max-height: calc($mobile-menu-top-offset - $mobile-head-border-bottom-thickness);

    #mobile-nav-main-buttons {
        display: flex;
        gap: 1.25rem;
        height: 2.5rem;
        min-height: 2.5rem;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        .mobile-nav-main-button {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .mobile-nav-main-button-icon {
                width: 1.5rem;
                max-width: 1.5rem;
                height: 1.5rem;
                max-height: 1.5rem;
                overflow: hidden;
            }
            .mobile-nav-main-button-text {
                height: 1rem;
                max-height: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #000;
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
            &.active {
                .mobile-nav-main-button-text {
                    color: $interactive-main;
                }
                .mobile-nav-main-button-icon > svg > g > path {
                    fill: $interactive-main;
                }
            }
        }
    }
}

#mobile-menu-overlay {
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    background-color: white;
    top: $mobile-menu-top-offset;
    width: 100%;
    height: calc(100dvh - $mobile-menu-top-offset);
    box-sizing: border-box;
    padding-top: $mobile-menu-overlay-padding-vertical;
    @include mac-scrollbar-vertical;
    div#mobile-menu-overlay-top {
        h6 {
            padding-left: $mobile-menu-overlay-padding-horizontal;
            padding-bottom: $mobile-menu-item-padding-vertical;
        }
        div.mobile-menu-item-row {
            padding-left: $mobile-menu-overlay-padding-horizontal;
            padding-top: $mobile-menu-item-padding-vertical;
            padding-bottom: $mobile-menu-item-padding-vertical;
            border-bottom: 0.0625rem solid $mobile-menu-border-color;
        }
    }

    div#mobile-menu-overlay-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-top: 0.0625rem solid $mobile-menu-border-color;
        padding-left: $mobile-menu-overlay-padding-horizontal;
        padding-right: $mobile-menu-overlay-padding-horizontal;
        height: 3.25rem;
        width: calc(100% - (2 * $mobile-menu-overlay-padding-horizontal));
        p.body-body {
            color: $danger-main;
        }
    }
    #mobile-menu-overlay-container {
        .mobile-menu-overlay-section {
            padding-bottom: 2rem;

            h6,
            div.mobile-menu-overlay-badge-container {
                padding-left: $mobile-menu-overlay-padding-horizontal;
                padding-right: $mobile-menu-overlay-padding-horizontal;
            }

            h6 {
                color: $mlink-primary;
            }

            .mobile-menu-overlay-badge-container {
                margin-top: 0.5rem;
                display: flex;
                gap: 0.3125rem 1.25rem;
                flex-wrap: wrap;
                margin-bottom: 1.4375rem;
            }

            div.mobile-menu-overlay-menu-header {
                margin-top: calc(0.875rem - 0.6875rem);
                padding-bottom: 1rem;
                padding-left: $mobile-menu-overlay-padding-horizontal;
                padding-right: $mobile-menu-overlay-padding-horizontal;
                border-radius: 0.9375rem;
                display: flex;
                flex-direction: row;
                gap: 1rem;
                align-items: center;
                height: 3rem;
                margin-left: $mobile-menu-overlay-padding-horizontal;
                margin-right: $mobile-menu-overlay-padding-horizontal;
                &:active {
                    background-color: #e6f3ff;
                    border-radius: 0.9375rem;
                }
                p.menu-header {
                    color: $mlink-primary;
                    font-size: 1rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%;
                }

                .mlink-icon {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            div.mobile-menu-overlay-menu-items {
                padding-left: $mobile-menu-overlay-padding-horizontal;
                padding-right: $mobile-menu-overlay-padding-horizontal;
                display: flex;
                flex-direction: column;
                height: calc(100dvh - $mobile-menu-top-offset - 4rem);
                &.mobile-menu-overlay-menu-items-with-header {
                    height: calc(100dvh - $mobile-menu-top-offset - 1.6875rem - 64px - 4rem);
                }
                .mobile-menu-overlay-menu-item {
                    display: flex;
                    height: 3rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 1.125rem;
                    padding-left: 1.125rem;
                    &:active {
                        background-color: #e6f3ff;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    p.body-body {
                        color: #22272a;
                    }
                }
                p.region-name-paragraph {
                    color: var(--mLINK-neutrals-gray7, #3a4146);
                    padding-top: 0.62rem;
                    padding-bottom: 0.62rem;
                    font-size: 0.75rem;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 150%; /* 1.125rem */
                    letter-spacing: 0.0075rem;
                }
            }
            div.mobile-menu-overlay-menu-item-facility {
                display: flex;
                padding: 0.375rem 1rem;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .facility-info-short {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 1.5rem;
                    .facility-info-short-container {
                        display: flex;
                        padding-right: 0.3125rem;
                        align-items: center;
                        gap: 0.75rem;
                        & > .facility-info-short-image {
                            border-radius: 0.625rem;
                            width: 2.25rem;
                            height: 2.25rem;
                            &:active {
                                background-color: #e6f3ff;
                            }
                            & > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: inherit;
                            }
                        }
                        & > .facility-info-short-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            p:first-child {
                                color: var(--mLINK-neutrals-gray8, #22272a);
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%; /* 1.3125rem */
                                letter-spacing: 0.00875rem;
                            }
                            p:last-child {
                                color: var(--mLINK-neutrals-gray6, #545962);
                                font-size: 0.625rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%; /* 0.9375rem */
                                letter-spacing: 0.00625rem;
                            }
                        }
                    }
                }
                & > div:last-child {
                    & :active {
                        background-color: #e6f3ff;
                        border-radius: 50%;
                    }
                }
            }
            div.mobile-menu-overlay-menu-item-facility-list-item {
                .facility-info-short {
                    display: flex;
                    padding: 0.375rem 1rem;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 2.1875rem;
                    align-self: stretch;
                    .facility-info-short-container {
                        display: flex;
                        padding-right: 0.3125rem;
                        align-items: center;
                        gap: 0.75rem;
                        & > .facility-info-short-image {
                            border-radius: 0.625rem;
                            width: 2.25rem;
                            height: 2.25rem;
                            & > img {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                                border-radius: inherit;
                            }
                        }
                        & > .facility-info-short-info {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            &:active {
                                background-color: #e6f3ff;
                            }
                            p:first-child {
                                color: var(--mLINK-neutrals-gray8, #22272a);
                                font-size: 0.875rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%; /* 1.3125rem */
                                letter-spacing: 0.00875rem;
                            }
                            p:last-child {
                                color: var(--mLINK-neutrals-gray6, #545962);
                                font-size: 0.625rem;
                                font-style: normal;
                                font-weight: 400;
                                line-height: 150%; /* 0.9375rem */
                                letter-spacing: 0.00625rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.mobile-menu-overlay-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 9.25rem;
    height: 1.875rem;
    flex-shrink: 0;
    border-radius: 1.875rem;
    background: $mlink-primary;
    color: white;

    &.disabled {
        background-color: $gray-lighter;
        color: $gray-lightest;
    }
}

.mobile-notification-cards {
    position: absolute;
    left: 0.5rem;
    top: 4rem;
    max-height: 25rem;
    overflow-y: auto;
    z-index: 1000;
    width: 90%;
    padding: 0.5rem;
    background-color: white;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    @include mac-scrollbar-vertical;
}

.mobile-notification-cards.show {
    display: block;
}
// Animations
@keyframes bellWiggle {
    0%,
    100% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(-15deg);
    }
    40% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(5deg);
    }
}

.bell-wiggle {
    animation: bellWiggle 0.5s ease;
}
.slide-right-enter-active {
    position: absolute;
    animation: slide-in-left 0.255s ease-out forwards;
    transition: all 0.255s;
    width: 100%;
}
.slide-right-leave-active {
    animation: slide-out-right 0.255s ease-out forwards;
    transition: all 0.255s;
    position: absolute;
    width: 100%;
}
.slide-left-enter-active {
    position: absolute;
    animation: slide-in-right 0.255s ease-out forwards;
    transition: all 0.255s;
    width: 100%;
}
.slide-left-leave-active {
    animation: slide-out-left 0.255s ease-out forwards;
    transition: all 0.255s;
    position: absolute;
    width: 100%;
}
@keyframes slide-in-right {
    from {
        transform: translateX(100vw);
    }
    to {
        transform: translateX(0);
    }
}
@keyframes slide-out-right {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100vw);
    }
}
@keyframes slide-out-left {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100vw);
    }
}
@keyframes slide-in-left {
    from {
        transform: translateX(-100vw);
    }
    to {
        transform: translateX(0);
    }
}

.menu-divider {
    border-bottom: 1px solid $border-gray;
    height: 0px;
    width: 70%;
    margin-left: 60px;
}
</style>

<template>
    <div v-if="show" id="sidecar-menu" v-click-outside.eager="outsideClick">
        <div v-if="store.currentFacility" id="sidecar-top" class="hover-hand">
            <div id="sidecar-image" @click="goToCurrentFacility">
                <img :src="src" alt="Facility Image" />
            </div>

            <div id="facility-verbose" @click="goToCurrentFacility">
                <span :title="store.currentFacility?.name">{{ store.currentFacility?.name }}</span>
                <span @click.stop="goToCurrentCompanyFacilities">{{ computedSubLabel }}</span>
            </div>

            <div id="facility-selector-arrows" :style="showFacilityPicker ? 'background: #e6f3ff' : ''" @click="toggleFacilityPicker">
                <MCIcon icon="chevron-up_gray-darkest" :size="14" />
                <MCIcon icon="chevron-down_gray-darkest" :size="14" />
            </div>

            <div v-if="showFacilityPicker" id="facility-picker">
                <div id="facility-fuzzy-search" class="facility-picker-section">
                    <MCTextInput
                        v-model="facilitySearch"
                        placeholder="Søk"
                        autofocus
                        iconPlacement="left"
                        icon="search"
                        size="medium"
                        @keyup.escape="show = false"
                    />
                </div>

                <div id="see-all-facilities" class="facility-picker-section" role="button" @click="goToCompanyFacilities">
                    Se alle fasiliteter
                </div>

                <MCSpinner v-if="fetchingFacilities" />
                <div v-else id="regions-and-facilities" class="facility-picker-section">
                    <div v-for="thing in searchedThings" :key="thing.regionId" class="region-box">
                        <span>{{ thing.regionName }}</span>
                        <div v-for="facility in thing.facilities" :key="facility.id" class="facility-box" @click="goToFacility(facility)">
                            <div class="circle-image">
                                <img
                                    v-if="loadOriginals"
                                    :src="facility.image?.image_small ? facility.image.image_small : '/facility_img_placeholder.png'"
                                    :alt="`facility iamge for ${facility.name}`"
                                    @error="loadOriginals = true"
                                />
                                <img
                                    v-else
                                    :src="facilityImage(facility)"
                                    :alt="`facility iamge for ${facility.name}`"
                                    @error="loadOriginals = true"
                                />
                            </div>
                            <div class="facility-inline-name">
                                {{ facility.name }}
                            </div>
                            <div v-if="facility.locality.name" class="inline-pill">
                                {{ facility.locality.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="store.currentFacility" id="sidecar-bottom">
            <!-- Arbeidsordre -->
            <div
                class="sidecar-item hover-hand"
                :title="hasMaintenanceModule ? t('side_nav.workorders') : t('side_nav.must-have-maintenance-module-workorder')"
                :aria-label="hasMaintenanceModule ? t('side_nav.workorders') : t('side_nav.must-have-maintenance-module-workorder')"
                :class="{ 'module-disabled': !hasMaintenanceModule }"
                @click="goToWorkOrders"
            >
                <MCIcon :icon="hasMaintenanceModule ? 'build_gray-darkest' : 'build_chevron-gray'" :size="16" />
                <p class="body-small">
                    {{ t('side_nav.workorders') }}
                </p>
            </div>

            <!-- Remarks -->
            <div v-if="showRemarks" id="remarks-link" class="sidecar-item hover-hand" @click="goToRemarks">
                <MCIcon icon="warning_gray-darkest" :size="16" />
                <p class="body-small">{{ t('common.remarks') }}</p>
            </div>

            <!-- Dokumenter -->
            <div
                class="sidecar-item hover-hand"
                :aria-label="t('side_nav.documents')"
                :title="t('side_nav.documents')"
                tabindex="0"
                @click="goToDocuments"
            >
                <MCIcon icon="description_gray-darkest" :size="16" />
                <p class="body-small">
                    {{ t('side_nav.documents') }}
                </p>
            </div>

            <!-- Utstyr -->
            <!-- New -->
            <div
                class="sidecar-item hover-hand"
                :aria-label="t('side_nav.equipment')"
                :title="t('side_nav.documents')"
                tabindex="0"
                @click="goToEquipment"
            >
                <MCIcon icon="equipment_gray-darkest" :size="16" />
                <p class="body-small">
                    {{ t('side_nav.equipment') }}
                </p>
            </div>

            <!-- Sjekklister -->
            <div
                class="sidecar-item hover-hand"
                :class="{ 'module-disabled': !hasMaintenanceModule }"
                :title="hasMaintenanceModule ? t('side_nav.workorders') : t('side_nav.must-have-maintenance-module-checklist')"
                :aria-label="hasMaintenanceModule ? t('side_nav.workorders') : t('side_nav.must-have-maintenance-module-checklist')"
                tabindex="0"
                @click="goToChecklists"
            >
                <MCIcon :icon="hasMaintenanceModule ? 'checklist-block_gray-darkest' : 'checklist_chevron-gray'" :size="16" />
                <p class="body-small">{{ t('side_nav.checklists') }}</p>
            </div>

            <!-- Support -->
            <div
                class="sidecar-item hover-hand"
                :title="t('side_nav.support')"
                :aria-label="t('side_nav.support')"
                :tabindex="0"
                @click="goToSupport"
            >
                <MCIcon icon="support-agent_gray-darkest" :size="16" />
                <p class="body-small">
                    {{ t('side_nav.support') }}
                </p>
            </div>

            <!-- Connect -->
            <div
                v-if="showConnect"
                class="sidecar-item hover-hand"
                :title="t('side_nav.connect')"
                :aria-label="t('side_nav.connect')"
                @click="goToConnectDashboard"
            >
                <MCIcon icon="dashboard_gray-darkest" :size="16" />
                <p class="body-small">Connect Dashbord</p>
            </div>
        </div>

        <MCInfoBanner
            v-if="!store.currentFacility"
            label="Dette selskapet har ingen fasiliteter, du kan opprette fasilitet fra Admin-menyen."
            type="warning"
        />
    </div>
</template>

<script setup lang="ts">
import Fuse from 'fuse.js'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { useGlobalStore } from '@/stores/global'
import companyApi from '@/api/companies'
import facilityApi from '@/api/facilities'

import type { IFacilityGroup, IFacilityImage, IRegion } from '../company/interfaces/IRegion'
import type { IFacilityV3 } from '../facility/interfaces/IFacility'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ILocality } from '@/modules/CORE/company/interfaces/ILocality'

// store & router & translation
const { t } = useI18n()
const store = useGlobalStore()
const router = useRouter()

// v-model
const show = defineModel<boolean>({ default: false })

// reactive variables
const showFacilityPicker = ref<boolean>(false)
const facilitySearch = ref<string>('')
const loadOriginals = ref<boolean>(false) // default is to load _small facility images
const fetchingFacilities = ref<boolean>(false)

// computed
const showRemarks = computed(() => {
    if (store.currentUser?.is_superuser) return true // project managers
    if (store.currentCompany?.enable_yard) return true // inspectors
    if (store.currentCompany?.is_subcontractor) return false
    return false
})

const src = computed(() => {
    if (store.currentFacility?.image?.image_small) {
        return store.currentFacility.image.image_small
    }
    return '/facility_img_placeholder.png'
})

const hasMaintenanceModule = computed(() => {
    return store.currentFacility?.maintenance_enabled
})

const showConnect = computed(() => {
    // showing connect requires current facility to be present
    if (!store.currentFacility) return false
    const currentFacility = store.currentFacility

    // showing connect requires connect_dashboard_enabled to be true OR user must be a superuser
    if (currentFacility.connect_dashboard_enabled || store.currentUser?.is_superuser) return true

    // else default return false
    return false
})

const facilities = ref<any[]>([])

const originalFacilityGroupList = ref<IFacilityGroup[]>([])

interface IThinkFacility {
    id: string
    name: string
    imgSrc: string
    image: IFacilityImage
    locality: {
        id: string
        name: string
    }
}

interface IThing {
    regionId: string
    regionName: string
    facilities: IThinkFacility[]
}

const searchOptions = {
    includeScore: true,
    shouldSort: true,
    keys: ['name', 'locality.name'],
}

const searchedThings = computed(() => {
    if (facilitySearch.value.trim() === '') {
        return newThings.value
    }

    // list if facilities only
    const facilityList = newThings.value.map((thing) => thing.facilities).flat()
    // improvement potential: also enable search by other facility properties,
    // like e.g. region name, company name (for lessors and manufacture companies), and build number, project manager, yard, design, etc.

    // fuzzy search setup
    const fuse = new Fuse(facilityList, searchOptions)
    const searchResults = fuse.search(facilitySearch.value)

    // filter by results with score less than x (best results have score very close to 0)
    const filteredResults = searchResults.filter((result) => result.score && result.score < 0.5)

    // deep clone
    const searchedThings = JSON.parse(JSON.stringify(newThings.value))
    for (const thing of searchedThings) {
        thing.facilities = thing.facilities.filter((facility: IThinkFacility) => {
            return filteredResults.some((result) => result.item.id === facility.id)
        })
    }

    return searchedThings
})

const newThings = computed(() => {
    const strangeThings: IFacilityGroup[] = originalFacilityGroupList.value

    // first - establish regions (or groups) that has facilities
    const regionsWithFacilities: IFacilityGroup[] = []
    for (const thing of strangeThings) {
        // find out if this region has any facilities
        for (const possibleLocality of thing.sub_groups) {
            if (possibleLocality.facilities.length > 0) {
                regionsWithFacilities.push(thing)
                break
            }
        }
    }

    const goodThings: IThing[] = []

    // second - create the new data structure
    for (const region of regionsWithFacilities) {
        const facilities: IThinkFacility[] = []
        for (const locality of region.sub_groups) {
            for (const facility of locality.facilities) {
                facilities.push({
                    id: facility.id || ('' as string),
                    name: facility.name,
                    imgSrc: facility.image_url,
                    image: facility.image,
                    locality: {
                        id: locality.id || ('' as string),
                        name: locality.name,
                    },
                })
            }
        }

        goodThings.push({
            regionId: region.id || ('' as string),
            regionName: region.name,
            facilities,
        })
    }

    return goodThings
})

const facilityImage = (facility: IThinkFacility) => {
    if (facility.image) {
        if (facility.image.image_small) {
            return facility.image.image_small
        }
        return facility.image.image
    } else {
        return '/facility_img_placeholder.png'
    }
}

const fetchAllFacilities = async () => {
    fetchingFacilities.value = true
    const allFacilities = await facilityApi.allFacilitiesV3()
    facilities.value = allFacilities.data as IFacilityV3[]

    await store.waitForCurrentCompany()
    originalFacilityGroupList.value = await companyApi.fetchFacilityGroups(store.currentCompany?.id as UUIDv4)
    fetchingFacilities.value = false
}

// computed
const computedSubLabel = computed(() => {
    const currentLocalityId = store.currentFacility?.locality
    const currentRegionId = store.currentFacility?.region

    // Find the locality within the regions
    let localityName = ''
    let regionName = ''

    store.currentCompany?.regions.forEach((region: IRegion) => {
        region.localities?.forEach((locality: ILocality) => {
            if (locality.id === currentLocalityId) {
                localityName = locality.name
            }
        })

        if (region.id === currentRegionId) {
            regionName = region.name
        }
    })

    // Return the locality name if found, otherwise return the region name or company name
    return localityName || regionName || store.currentCompany?.name
})
// methods
const toggleFacilityPicker = () => {
    showFacilityPicker.value = !showFacilityPicker.value
    if (showFacilityPicker.value) {
        fetchAllFacilities()
    }
}

const outsideClick = () => {
    show.value = false
}

const goToCurrentCompanyFacilities = () => {
    router.push({ name: 'company-facilities', params: { companyId: store.currentCompany?.id } })
    show.value = false
}
const goToCurrentFacility = () => {
    router.push({ name: 'facility-detail', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToFacility = (facility: any) => {
    store.setCurrentFacilityByFacilityId(facility.id)
    router.push({ name: 'facility-detail', params: { facilityId: facility.id } })
    showFacilityPicker.value = false
    show.value = false
}

const goToWorkOrders = () => {
    router.push({ name: 'workorder-page', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToDocuments = () => {
    router.push({ name: 'document-list', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToEquipment = () => {
    router.push({ name: 'equipment-list', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToChecklists = () => {
    router.push({ name: 'checklist-page', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToRemarks = () => {
    router.push({ name: 'remarks-page', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}
const goToSupport = () => {
    router.push({ name: 'support-page', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToConnectDashboard = () => {
    router.push({ name: 'connect-dashboard', params: { facilityId: store.currentFacility?.id } })
    show.value = false
}

const goToCompanyFacilities = () => {
    router.push({ name: 'company-facilities', params: { companyId: store.currentCompany?.id } })
    show.value = false
}

// lifecycle hooks
onMounted(() => {
    // should only call this on show
    // fetchAllFacilities()
})

// watch
watch(show, (value) => {
    if (value === false) {
        showFacilityPicker.value = false
    }
})
</script>

<style lang="scss">
@import '@/scss/colors.scss';

#facility-fuzzy-search {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
    .mc-input-parent {
        border: none;
        box-shadow: none;
    }
}

#see-all-facilities {
    border-top: 0.0625rem solid #ccc;
    padding-left: 0.625rem;
    color: var(--mLINK-neutrals-gray8, #22272a);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    &:hover {
        background: $interactive-lightest;
    }
}

#regions-and-facilities {
    display: flex;
    flex-direction: column;
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;

    .region-box {
        padding-top: 0.5rem;
        > span {
            color: #3a4146;

            font-size: 0.75rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 0.0075rem;
            display: block;
            margin-top: 0.125rem;
            padding-left: 0.625rem;
        }

        // first facility-box
        .facility-box {
            width: calc(100% - 0rem);
            display: flex;
            flex-direction: row;
            gap: 0.5rem;
            justify-content: flex-start;
            align-items: center;
            padding-top: 0.625rem;
            padding-bottom: 0.625rem;
            &:hover {
                background: $interactive-lightest;
            }
            .circle-image {
                padding-left: 0.625rem;
                $image-size: 1.75rem;
                width: $image-size;
                height: $image-size;
                border-radius: 50%;
                overflow: hidden;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: inherit;
                }
            }
            .facility-inline-name {
                color: #22272a;
                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
            }
            .inline-pill {
                display: flex;
                padding: 0.0625rem 0.25rem;
                justify-content: center;
                align-items: center;
                gap: 0.125rem;
                border-radius: 1.25rem;
                border: 0.0625rem solid var(--mLINK-neutrals-gray4, #a1a9af);
                background: var(--mLINK-neutrals-gray1, #f8f9f9);

                color: var(--mLINK-neutrals-gray8, #22272a);

                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.0088rem;
            }
        }
    }
}

#facility-picker {
    position: absolute;
    top: 0.5rem;
    left: calc(5rem + 7.5rem + 0rem);
    width: 100%;
    z-index: 10005;
    width: 20rem;
    height: fit-content;
    min-height: 25rem;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: auto;

    border-radius: 0.125rem;
    border: 0.0938rem solid $border-gray;
    background: var(--Color-neutrals-white, #fff);

    /* $dds-shadow/2-onlight */
    box-shadow: 0.125rem 0.25rem 0.5rem 0rem rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .facility-picker-section {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
        width: calc(100%);
        height: fit-content;
    }
}

.sidecar-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
    p {
        color: $mlink-primary;
        font-size: 0.875rem;
        letter-spacing: 0.0088rem;
    }
    &.module-disabled {
        p {
            color: $chevron-gray;
        }
    }
}

#sidecar-menu {
    background-color: #fff;
    flex-shrink: 0;
    height: 100%;
    position: fixed;
    top: 0;
    left: 5rem;
    z-index: 10000;
    border-radius: 0rem 0.5rem 0.5rem 0rem;
    border-left: 0.0625rem solid $navigation-gray;
    box-shadow: 0.0625rem 0rem 0.5rem 0rem rgba(0, 0, 0, 0.1);

    padding: 0.875rem;
    width: calc(12.5rem - (2 * 0.875rem));

    #sidecar-top {
        width: 10.625rem;
        height: 2.25rem;

        display: flex;
        justify-content: space-between;
        align-items: center;

        #sidecar-image {
            $image-size: 2.25rem;
            width: $image-size;
            min-width: $image-size;
            height: $image-size;
            border-radius: 0.625rem;
            overflow: hidden;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: inherit;
            }
        }

        #facility-verbose {
            flex-grow: 1;
            margin-left: 0.625rem;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            // first span
            > span {
                color: var(--mLINK-neutrals-gray8, #22272a);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.0088rem;
                width: 6.875rem;
                // text ellipsis
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            // second span
            > span:nth-child(2) {
                color: var(--mLINK-neutrals-gray6, #545962);
                font-size: 0.625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 0.0063rem;
            }
        }

        #facility-selector-arrows {
            flex-grow: 0;
            margin-left: 0.25rem;
            border-radius: 0.5rem;
            padding-bottom: 0.1875rem;
            padding-left: 0.0625rem;
            &:hover {
                background: #eaeaea;
            }
        }
    }

    #sidecar-bottom {
        margin-top: 1.3125rem;
        padding-left: 0.625rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0.375rem;
    }
}
</style>

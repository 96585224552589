import { api } from './ApiSettings'
import http from './http'

import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ISearchResponseRemarks } from '@/modules/CORE/search/interfaces/ISearch'
import type {
    IProfileResponsible,
    IRemarkChatPost,
    IRemarkDetailed,
    IRemarkDicipline,
    IRemarkSignatureObjectPost,
    ISubcontractor,
} from '@/modules/YARD/remarks/interfaces/IRemarks'
import type { AxiosResponse } from 'axios'

export default {
    remarkExcelExport: async (facilityId: UUIDv4, remarksIds: UUIDv4[]): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/remarks-v4/excel`)
        const postBody = { remarksIds }
        const response = await http.postWithFetch(url, postBody)
        if (response.status !== 200) {
            return null
        } else {
            return response.blob()
        }
    },
    fetchRemarkById: async (facilityId: UUIDv4, remarkId: UUIDv4): Promise<IRemarkDetailed> => {
        const url = api(`v1/facilities/${facilityId}/remarks/${remarkId}/`)
        const errorMessage = 'Failed to fetch remark.'
        let response = null
        let remark = null
        try {
            response = await http.get(url)
            if (response.status === 200) {
                remark = response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return remark
    },
    createRemark: async (facilityId: UUIDv4, data: any): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/remarks/`)
        if ('attachments' in data) data.attachments = Object.values(data.attachments)
        const errorMessage = 'Failed to create remark.'

        let response = null
        let remark = null

        try {
            response = await http.post(url, data)

            if (response.status === 201) {
                remark = response.data
            }
        } catch (e) {
            console.error('No remark, ERROR: ', errorMessage, e)
        }

        return { response, remark }
    },
    updateRemark: async (facilityId: UUIDv4, remarkId: UUIDv4, data: any): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/remarks/${remarkId}/`)
        if ('attachments' in data) data.attachments = Object.values(data.attachments)
        const errorMessage = 'Failed to update remark.'
        let response = null
        let remark = null

        try {
            response = await http.patch(url, data)

            if (response.status === 200) {
                remark = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }

        return { response, remark }
    },
    archiveRemark: async (facilityId: UUIDv4, remarkId: UUIDv4): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/remarks/${remarkId}/`)
        const errorMessage = 'Failed to delete remark.'
        let response = null

        try {
            response = await http.delete(url)
        } catch (e) {
            console.error(errorMessage, e)
        }

        return response
    },
    fetchavailableDisciplines: async (facilityId: UUIDv4): Promise<IRemarkDicipline[]> => {
        const url = api(`v1/facilities/${facilityId}/remarks/available-disciplines`)

        const errorMessage = 'Failed to get available disciplines.'

        let response = null
        let disciplines = null

        try {
            response = await http.get(url)

            if (response.ok) {
                disciplines = await response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return disciplines
    },

    fetchResponsibleForRemarks: async (facilityId: UUIDv4): Promise<IProfileResponsible[]> => {
        const url = api(`v1/facilities/${facilityId}/v4/remarks/available-responsible`)
        const errorMessage = 'Failed to get responsible persons.'

        let response = null
        let responsible = null

        try {
            response = await http.get(url)

            if (response.ok) {
                responsible = await response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return responsible
    },
    fetchSubcontractors: async (): Promise<ISubcontractor[]> => {
        const url = api('v4/subcontractors')
        const errorMessage = 'Failed to get subcontractors.'

        let response = null
        let subcontractors = null

        try {
            response = await http.get(url)

            if (response.ok) {
                subcontractors = await response.json()
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return subcontractors
    },
    signRemark: async (facilityId: UUIDv4, remarkId: UUIDv4, signatureObject: IRemarkSignatureObjectPost): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/v4/remarks/${remarkId}/signature/`)
        const errorMessage = 'Failed to sign remark.'
        let response = null
        let signature = null

        try {
            response = await http.post(url, signatureObject)

            if (response.status === 201) {
                signature = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }

        return { response, signature }
    },
    createRemarkChat: async (remarkId: UUIDv4, facilityId: UUIDv4, chat: IRemarkChatPost): Promise<any> => {
        // If your backend no longer needs facilityId (because the URL is nested under the remark),
        // remove it from the URL.
        const url = api(`v1/facilities/${facilityId}/v4/chat-remark/${remarkId}/chats/`) // Notice trailing slash
        const errorMessage = 'Failed to create remark chat.'
        let response = null
        try {
            response = await http.post(url, chat)
            if (response.status === 201) {
                chat = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }
        return { response, chat }
    },

    // FacilityID should not be necassary, but it is with the current way the remark app is built
    searchRemarksByFacilityIds: async (
        facilityId: UUIDv4,
        scope: UUIDv4[] | 'all',
        search: string,
    ): Promise<ISearchResponseRemarks[] | undefined> => {
        const url = api(`v1/facilities/${facilityId}/v4/remarks/search-for-remarks`)
        const errorMessage = 'Failed to search remarks.'
        let response: AxiosResponse | undefined
        let remarks: any[] = []

        try {
            response = await http.post(url, {
                scope,
                search,
                limit: 5,
            })
            if (response.status === 200) {
                remarks = response.data
            }
        } catch (e: any) {
            console.error(errorMessage, e)
        }
        // scope will either check contain a list of Facility PK, and if so i want to search the remarks for those facilities
        // if scope is 'all' i want to search all remarks
        return remarks
    },

    deleteRemarkChat: async (remarkId: UUIDv4, facilityId: UUIDv4, chatId: UUIDv4): Promise<any> => {
        // Adjust the base URL as needed (for example, if your app URLs are under /api/)
        const url = api(`v1/facilities/${facilityId}/v4/chat-remark/${remarkId}/chats/${chatId}/`)
        let response: any = null
        try {
            response = await http.delete(url)
        } catch (e) {
            console.error('Failed to delete remark chat.', e)
        }
        return response
    },
    editRemarkChat: async (remarkId: UUIDv4, facilityId: UUIDv4, chatId: UUIDv4, updatedChat: Partial<IRemarkChatPost>): Promise<any> => {
        const url = api(`v1/facilities/${facilityId}/v4/chat-remark/${remarkId}/chats/${chatId}/`)
        let response: any = null
        try {
            response = await http.patch(url, updatedChat)
        } catch (e) {
            console.error('Failed to edit remark chat.', e)
        }
        return response?.data
    },
}

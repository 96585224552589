import { ref } from 'vue'
import { createI18n } from 'vue-i18n'

const initialLocale = (): 'en' | 'no' => {
    const locale = window.localStorage.getItem('mlink.settings.locale')
    if (locale) {
        return locale as 'en' | 'no'
    }

    const language = window.navigator.language.toLowerCase()
    const norwegianLanguageCodes = ['nb-no', 'nb', 'no', 'no-no', 'nn-no', 'nn']

    if (norwegianLanguageCodes.includes(language)) {
        return 'no'
    }
    return 'en'
}

const defaultLocale = initialLocale()
const currentLocale = ref(defaultLocale)

const loadLocaleMessages = async (locale: any) => {
    const messages = await import(`./locales/${locale}.json`)
    return messages.default
}

const i18n = createI18n({
    legacy: false, // If using Vue 3, set legacy mode to false
    locale: currentLocale.value,
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        [currentLocale.value]: await loadLocaleMessages(currentLocale.value),
    },
    missing: (locale, key) => console.error('Translation not found! Locale:', locale, 'translation key:', key),
})

const setLanguage = async (lang: any) => {
    currentLocale.value = lang
    window.localStorage.setItem('mlink.settings.locale', lang)

    if (!i18n.global.availableLocales.includes(lang)) {
        const messages = await loadLocaleMessages(lang)
        i18n.global.setLocaleMessage(lang, messages)
    }
    i18n.global.locale.value = lang
}

export { i18n, setLanguage, currentLocale }

<template>
    <div class="bottom-content">
        <div id="moen-marin-logo-container">
            <img src="/MM_hovedlogo_blaa_stor.png" alt="" height="100" />
        </div>
        <!-- placeholder logo for mLink -->
        <div id="m-link-logo-container">
            <p class="body-body">
                mLink
            </p>
        </div>
    </div>
</template>

<template>
    <nav id="desktop-menu" @mouseleave="showCompanySwitch = false">
        <div id="desktop-menu-top">
            <!-- Moen Marin logo -->
            <MenuLogo />

            <!-- Søk -->
            <MenuSearch v-if="showMenuSearch" v-model="openSearch" />

            <div v-if="openSearch" id="search-container-backdrop" @click.self="backdropClick()">
                <GlobalSearch @searchResultClicked="openSearch = false" @escapeClicked="openSearch = false" />
            </div>

            <div class="desktop-menu-divider" />

            <!-- * Superuser * -->
            <MenuButton
                v-if="showSuperuserMenuItem"
                text="Superadmin"
                icon="superuser_gray-darkest"
                :active="isRoute('superuser')"
                @action="goToRoute({ name: 'superuser-page' })"
            />

            <!-- * Home * -->
            <MenuButton
                :text="t('side_nav.home')"
                icon="home_gray-darkest"
                :active="isRoute('home')"
                @action="goToRoute({ name: 'home' })"
            />

            <!-- * All Remarks for subcontractors (regardless of company) -->
            <MenuButton
                v-if="showRemarksForSubcontractors"
                :text="t('common.remarks')"
                customTextStyle="margin-left: 0rem;width: 4.5rem; text-align: center;;"
                icon="warning_gray-darkest"
                :active="isRoute('subcontractor-remarks')"
                @action="goToRoute({ name: 'subcontractor-remarks', params: { companyId: store?.currentCompany?.id } })"
            />

            <div class="desktop-menu-divider" @mouseover="showCompanySwitch = false" />
            <p
                class="body-small"
                style="color: gray; font-size: 12px; margin-top: -8px; margin-bottom: -14px"
                @mouseover="showCompanySwitch = false"
            >
                {{ t('common.company') }}
            </p>

            <!-- * Current Company * -->
            <div>
                <div v-click-outside.eager="outsideCompanyMenuClickHandler">
                    <MenuButton
                        v-if="store.currentCompany"
                        :text="customEllipsis(store.currentCompany?.name)"
                        icon="business_gray-darkest"
                        :active="isRoute('company')"
                        :tinyArrowToIndicateSubMenu="userHasAccessToMultipleCompanies"
                        @action="goToRoute({ name: 'company-facilities', params: { companyId: store?.currentCompany?.id } })"
                        @mouseover="showCompanySwitch = true"
                    />

                    <!-- Bedriftsbytter -->
                    <MenuCompanySwitcher
                        v-if="userHasAccessToMultipleCompanies && showCompanySwitch"
                        @closeMenu="outsideCompanyMenuClickHandler"
                        @mouseleave="showCompanySwitch = false"
                    />
                </div>
            </div>

            <!-- * Company Admin * -->
            <MenuButton
                v-if="store.userIsAdminOrSuperUser && store.currentCompany"
                text="Admin"
                icon="settings_gray-darkest"
                :active="isRoute('company-admin')"
                @mouseover="showCompanySwitch = false"
                @action="goToRoute({ name: 'company-admin', params: { companyId: store?.currentCompany?.id } })"
            />

            <!-- * Data explorer (all data within company) * -->
            <MenuButton
                v-if="showDataExplorer"
                :text="t('side_nav.data-explorer')"
                customTextStyle="margin-left: 0rem;width: 4.5rem; text-align: center;font-size: 11px;"
                icon="dashboard-filled_gray-darkest"
                :active="isRoute('data-explorer')"
                @action="goToRoute({ name: 'data-explorer', params: { companyId: store?.currentCompany?.id } })"
            />

            <!-- * Map * -->
            <MenuButton
                v-if="showMap"
                :text="t('side_nav.map')"
                icon="map_gray-darkest"
                :active="isRoute('map')"
                @action="goToRoute({ name: 'company-map', params: { companyId: store?.currentCompany?.id } })"
            />

            <!-- * Company/Fleet Dashbord * -->
            <MenuButton
                v-if="store.currentUser?.is_superuser || store.currentCompany?.enable_dashboard"
                text="Dashbord"
                icon="dashboard_gray-darkest"
                :active="isRoute('dashboard')"
                @action="goToRoute({ name: 'dashboard', params: { companyId: store?.currentCompany?.id } })"
                @mouseover="showSidecarMenu = false"
            />

            <div class="desktop-menu-divider" />
            <p class="body-small" style="color: gray; font-size: 12px; margin-top: -8px; margin-bottom: -14px">
                {{ t('common.facility') }}
            </p>

            <!-- * Faciliteter * -->
            <MenuButton
                v-if="store.currentCompany"
                id="facility-menu-icon"
                :text="customEllipsis(store.currentFacility?.name ?? '-')"
                icon="directions-boat_gray-darkest"
                tinyArrowToIndicateSubMenu
                :active="isRoute('facility-context')"
                @action="goToCurrentFacility"
                @mouseover="openSidecar"
            />

            <!-- 2nd menu: facility context -->
            <SidecarMenu v-model="showSidecarMenu" @mouseleave="showSidecarMenu = false" />

            <div class="desktop-menu-divider" @mouseover="showSidecarMenu = false" />
        </div>

        <div
            v-if="showNotifications"
            v-click-outside="closeNotificationMenu"
            class="notification-cards"
            @mouseover="showSidecarMenu = false"
        >
            <MenuButton
                id="notification-settings-button"
                :text="t('side_nav.notifications')"
                icon="settings"
                style="margin-left: auto; padding: 0.25rem 1rem"
                @action="goToRoute({ name: 'personal-user-settings', query: { page: 'notifications-settings' } })"
            />
            <div class="notification-cards-inner">
                <NotificationCard
                    v-for="notification in store.notifications"
                    :key="notification.id"
                    :notification="notification"
                    :tabindex="0"
                />
                <div class="notification-link" @click="goToNotificationPage">
                    <router-link class="link-action" :to="routeLink">
                        {{ t('notifications.see-all-notifications') }}
                    </router-link>
                </div>
            </div>
        </div>

        <div id="desktop-menu-bottom">
            <div>
                <!-- Varsler -->
                <MenuButton
                    id="notification-button"
                    :text="t('side_nav.notifications')"
                    icon="notifications_gray-darkest"
                    :wiggleButton="store.newNotifications && !showNotifications"
                    :notificationsCount="notificationCount"
                    :active="showNotifications || router.currentRoute.value.meta.menuContext === 'notifications'"
                    @action="toggleNotifications"
                    @buttonWiggled="notificationButtonDoneWiggle"
                />

                <!-- Bruker -->
                <MenuButton
                    :text="t('common.user')"
                    icon="user-profile_gray-darkest"
                    biggerText
                    :active="showUserMenu || router.currentRoute.value.meta.menuContext === 'user'"
                    @click="showUserMenu = true"
                />
            </div>

            <div v-if="showUserMenu" id="user-menu-container" v-click-outside="closeUserMenu">
                <div class="user-menu-item user-row">
                    <span>
                        <b>{{ displayUserName(store.currentUser) }}</b>
                        <br />
                        <p class="body-small">
                            {{ store.currentUser?.email }}
                        </p>
                    </span>
                </div>
                <div
                    class="user-menu-item"
                    role="button"
                    :aria-label="t('user.manage_account')"
                    :title="t('user.manage_account')"
                    tabindex="0"
                    @click="userMenuClick('user-settings')"
                    @keydown.space="userMenuClick('user-settings')"
                >
                    <MCIcon icon="settings" :size="14" />
                    <span>{{ t('user.manage_account') }}</span>
                </div>
                <div
                    class="user-menu-item"
                    role="button"
                    :aria-label="t('side_nav.log_out')"
                    :title="t('side_nav.log_out')"
                    tabindex="0"
                    style="background-color: #eef2f4; border-top-left-radius: 0rem; border-top-right-radius: 0rem"
                    @click="userMenuClick('logout-page')"
                    @keydown.space="userMenuClick('logout-page')"
                >
                    <MCIcon icon="logout_gray-darkest" :size="14" />
                    <span>{{ t('side_nav.log_out') }}</span>
                </div>
            </div>
        </div>
    </nav>
</template>

<script setup lang="ts">
import _ from 'lodash'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

import { MenuButton, MenuCompanySwitcher, MenuLogo, MenuSearch, SidecarMenu } from '@/modules/CORE/navigation'
import { NotificationCard } from '@/modules/CORE/notifications/components'
import { GlobalSearch } from '@/modules/CORE/search'
import { useGlobalStore } from '@/stores/global'

import type { IUser } from '@/modules/CORE/interfaces/IUser'
import type { RouteLocationRaw } from 'vue-router'

import { prettifyUserDisplayname } from '@/utils'

const preventSidecarMenu = ref<boolean>(false)

const routeLink = computed((): RouteLocationRaw => {
    return { name: 'company-notifications', params: { companyId: store.currentCompany?.id } }
})

const showCompanySwitch = ref<boolean>(false)

const outsideCompanyMenuClickHandler = () => {
    showCompanySwitch.value = false
}

const openSidecar = () => {
    if (preventSidecarMenu.value) return
    showSidecarMenu.value = true
}

const goToCurrentFacility = () => {
    if (store.currentFacility) {
        router.push({ name: 'facility-detail', params: { facilityId: store.currentFacility.id } })

        preventSidecarMenu.value = true
        setTimeout(() => {
            preventSidecarMenu.value = false
        }, 1000)
    }
}
const showNotifications = ref(false)

const hasUnreadNotifications = computed(() => store.unseenNotificationsCount > 0)

const backdropClick = () => {
    openSearch.value = false
}

// This bounces the notification counter to 0 after 3 seconds. And marks the notifications as seen.
// BUT, only if the notification window is open. If the user closes the notification window, then we
// assume that the users hasn't looked to closely.
const debouncedResetNotificationCounter = _.debounce(() => {
    if (showNotifications.value) {
        const unseen_notifications = store.notifications.filter((notification) => !notification.seen)
        const wids = unseen_notifications.map((notification) => notification.ws_id)
        store.setNotificationsAsSeen(wids)
    }
}, 3000)

const userHasAccessToMultipleCompanies = computed(() => {
    if (store.companyList) {
        return store.companyList.length > 1
    }
    return false
})

const toggleNotifications = () => {
    if (store.notifications.length === 0) return

    if (!showNotifications.value) {
        debouncedResetNotificationCounter()
    }

    showNotifications.value = !showNotifications.value

    if (showNotifications.value) {
        showUserMenu.value = false
    }
}

const notificationCount = computed(() => {
    return store.unseenNotificationsCount
})

const closeNotificationMenu = () => {
    showNotifications.value = false
}

const { t } = useI18n()

const isSubcontractor = computed(() => {
    const company = store.currentCompany
    return company?.is_subcontractor
})

const showSidecarMenu = ref<boolean>(false)

const openSearch = ref<boolean>(false)

const router = useRouter()
const store = useGlobalStore()

const isRoute = (routeName: string): boolean => {
    if (routeName === 'facility-context') {
        // if the parameter facilityId is present in the route, we are in the facility context
        return Boolean(router.currentRoute.value.params.facilityId) || router.currentRoute.value.name === 'company-facilities'
    }

    return router.currentRoute.value.meta.menuContext === routeName
}

const goToRoute = (route: object) => {
    router.push(route)
}

const showUserMenu = ref<boolean>(false)

const userMenuClick = (route: string) => {
    router.push({ name: route })
    closeUserMenu()
}

const closeUserMenu = () => {
    showUserMenu.value = false
}

const notificationButtonDoneWiggle = () => {
    store.newNotifications = false
}

const populateCompanyList = async () => {
    store.companyList = await store.fetchCompaniesByUser()
}

const goToNotificationPage = () => {
    showNotifications.value = false
    router.push({ name: 'company-notifications', params: { companyId: store.currentCompany?.id } })
}

const customEllipsis = (text: string) => {
    if (!text) return '-'
    if (text === 'Moen Marin AS') return 'Moen Marin'
    const maxLength = 10
    if (text.length > maxLength) {
        const sliced = text.slice(0, maxLength)
        return `${sliced}...`
    }
    return text
}

onMounted(() => {
    populateCompanyList()

    window.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
            closeNotificationMenu()
        }
    })

    onUnmounted(() => {
        // clearInterval(interval)
        window.removeEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                closeNotificationMenu()
            }
        })
    })
})

const displayUserName = (user: IUser | null) => {
    if (!user) return '-'
    return prettifyUserDisplayname(user as IUser)
}

const showMenuSearch = computed(() => {
    // superusers and non-subcontractors can use the search
    return store.currentUser?.is_superuser || !isSubcontractor.value
})

const showSuperuserMenuItem = computed(() => {
    // only show backoffice if the user is a superuser
    return store.currentUser?.is_superuser
})

const showMap = computed(() => {
    return !isSubcontractor.value && store.currentCompany
})

const showDataExplorer = computed(() => {
    // show data explorer for superusers and company admins
    if (store.currentUser?.is_superuser) return true
    if (isSubcontractor.value) return false
    if (store.currentUser?.is_admin && store.currentCompany) return true
    return false
})

const showRemarksForSubcontractors = computed(() => {
    // There are some "hardcoded" manufacturers that are subcontractors,
    // This list must match the hardcoded UUID's in the API endpoint v4/subcontractors
    const hardcodedSubcontractors = [
        // '3230fd56-fd25-11e7-ace5-42010af00d40', // 'Moen Marin AS' (not shown in the menu, waiting for "Rollestyring")
        '299bf7ec-726b-4177-8f76-19f8b82c7173', // 'Mekon AS' (Should be shown in the menu as per now, before "Rollestyring")
    ]

    if (store.currentCompany) {
        return hardcodedSubcontractors.includes(store.currentCompany.id) || isSubcontractor.value
    }

    // show remarks for subcontractors
    return isSubcontractor.value
})
</script>

<style lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/scrollbar.scss';

.notification-cards {
    position: absolute;
    bottom: 5.5rem;
    left: 5rem;
    background-color: white;
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
    border-radius: 0.5rem;
    height: fit-content;
    max-height: 30rem;
    .notification-cards-inner {
        max-height: 25rem;
        overflow-y: auto;
        z-index: 1000;
        width: 25.75rem;
        padding: 0.5rem;
        @include mac-scrollbar-vertical;
        .notification-link {
            padding: 1rem;
            border-top: 1px solid #fafafa;
            background: white;
            display: flex;
            justify-content: center;
            position: sticky;
            bottom: -0.75rem;
        }
    }
}

.notification-cards.show {
    display: block;
}
@keyframes bellWiggle {
    0%,
    100% {
        transform: rotate(0);
    }
    20% {
        transform: rotate(-15deg);
    }
    40% {
        transform: rotate(10deg);
    }
    60% {
        transform: rotate(-10deg);
    }
    80% {
        transform: rotate(5deg);
    }
}

.bell-wiggle {
    animation: bellWiggle 0.5s ease;
}

#search-container-backdrop {
    z-index: 10000;
    position: absolute;
    top: 0;
    left: 0rem;
    padding: 0rem;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100dvh;
    overflow: hidden;
    z-index: 10005;

    display: flex;
    justify-content: center;
    align-items: center;
}

#desktop-menu {
    background: white;
    height: 100%;
    min-height: calc(100dvh - 2rem);
    padding: 0.5625rem;
    padding-top: 1.5rem;
    padding-bottom: 1.125rem;

    width: calc(5rem - (2 * 0.5625rem));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 10;
    #desktop-menu-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
    }
    #desktop-menu-bottom {
        #notification-button {
            padding-bottom: 1rem;
            border-bottom: 0.0625rem solid #d4d9dd;
            margin-bottom: 1rem;
        }
    }
}

#user-menu-container {
    position: fixed;
    bottom: 3.125rem;
    left: 3.75rem;
    background: white;
    width: 14.4375rem;
    z-index: 999;
    border-radius: 0.625rem;
    // very slight shadow
    box-shadow: 0rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
}

.user-menu-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.875rem;
    height: 1.5rem;

    border-radius: 0.375rem;
    padding-left: 1rem;

    $user-menu-item-padding: 0.3rem;
    padding-top: $user-menu-item-padding;
    padding-bottom: $user-menu-item-padding;

    span {
        color: #000;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5rem;
        &.red {
            color: $danger-main;
        }
    }

    &:hover {
        background: $hover-gray;
        cursor: pointer;
    }
    &:active {
        background: $active-gray;
    }

    &.user-row {
        height: 2rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
        &:hover {
            background: none;
            cursor: unset;
        }
        &:active {
            background: none;
        }
    }
}

.desktop-menu-divider {
    border-bottom: 0.0625rem solid $border-gray;
    width: 100%;
    margin-top: -10px;
    margin-bottom: -10px;
}
</style>

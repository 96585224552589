<template>
    <div id="menu-logo">
        <img
            v-if="decideScope() === 'prod'"
            :src="store.isMobileOrTabletScreenSize ? '/mlink-mobile-logo.png' : '/logo.png'"
            alt="Anchor logo"
        />
        <div v-if="decideScope() === 'staging'">STAGING</div>
        <div v-if="decideScope() === 'localhost'">LOCAL</div>
        <div v-if="decideScope() === 'development'">DEV</div>
    </div>
</template>

<script setup lang="ts">
import { useGlobalStore } from '@/stores/global'
import { host } from '@/api/ApiSettings'

// Standard
const store = useGlobalStore()
const decideScope = (): string => {
    if (host.includes('backend.staging.m-link.no')) {
        return 'staging'
    } else if (host.includes('backend.dev.m-link.no')) {
        return 'development'
    } else if (host.includes('localhost')) {
        return 'localhost'
    }
    return 'prod'
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';

#menu-logo {
    margin-bottom: -0.5rem;
    img {
        width: 2.625rem;
    }
    & > div {
        font-weight: bold;
        text-align: center;
        color: $warning-dark;
    }
}
</style>

import { api } from '@/api/ApiSettings'
import http from '@/api/http'
import supportApi, { mondayFileFetchUrl } from '@/api/support'

import generalQueries from './generalQueries'

import type {
    IClaimColumnValue,
    IClaimResponse,
    ICreateCommentForOrderPart,
    IMondayColumnValue,
    IOrderingPartStatus,
} from '../interfaces/ISupport'
import type { IDynamicUpload, IFileLinkObject, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'

import { prettifyUserDisplayname } from '@/utils'

export const performQuery = async (query: string) => {
    const url = api('v1/perform-monday-query/')
    let response
    try {
        const requestData = { data: query }
        response = await http.post(url, requestData)
        return response
    } catch (err) {
        throw new Error(`Failed to get monday response ${err}`)
    }
}

export const fetchOperationalMessagesFromMonday = async () => {
    const url = api('v4/operational-messages')
    let response
    try {
        const request = await http.get(url)
        if (request.ok) {
            return request.json()
        }
    } catch (err) {
        console.error('Failed to fetch operational messages from monday', err)
    }
    return response
}

export const mapColumnValuesByFieldname = (field: IMondayColumnValue, item: IClaimResponse): UUIDv4[] | string | IOrderingPartStatus => {
    const columnValue = item.column_values[item.column_values.findIndex((c: IClaimColumnValue) => c.column.title === field)].text
    if (field === 'Equipment') {
        if (columnValue === '') return []
        return columnValue.split(';').map((e: string) => e.trim()) as UUIDv4[]
    }
    return columnValue as string
}
export const mapPublicCommentsFromClaim = (updates: any): number => {
    let publicComments = 0
    for (let i = 0; i < updates.length; i++) {
        if (updates[i].text_body.toLowerCase().includes('[public]')) {
            publicComments++
        }
    }
    return publicComments
}

export const getMondayValuesWithRegex = (updateBody: string, decider: string): string => {
    let regex = null
    switch (decider) {
        case 'orderPartsDescription':
            regex = /Description: (.*?)<\/p>/
            break
        default:
            return ''
    }
    if (regex) {
        const match = updateBody.match(regex)
        return match ? match[1] : ''
    }
    return ''
}

export const translateMondayJSON = (field: IMondayColumnValue, item: IClaimResponse, key: string): boolean | string => {
    const columnValue = item.column_values[item.column_values.findIndex((c: IClaimColumnValue) => c.column.title === field)].value as string
    const json = JSON.parse(columnValue)
    return json[`${key}`]
}
export const createCommentForSpares = async (data: ICreateCommentForOrderPart, facilityId: UUIDv4) => {
    const author = `<p class="author">Author: ${data.user ? prettifyUserDisplayname(data.user) : '-'}</p>`
    const email = `<p class="email">Email: ${data.email || '-'}</p>`
    const phone = `<p class="phone">Phone: ${data.phone || '-'}</p>`
    const description = `<p class="description">Description: ${data.description || ''}</p>`

    let text = '[additional_info]'
    text += '<br/>'
    text += author
    text += email
    text += phone
    text += '<p>---</p>'
    text += description

    if (data.attachments) {
        const tempAtt: any = []

        data.attachments.forEach((attachment) => {
            if (facilityId) {
                tempAtt.push(supportApi.uploadAsFile(attachment.filename, attachment.file, facilityId))
            }
        })
        let locfiles: any[] = []
        await Promise.all(tempAtt).then((files: IDynamicUpload[]) => {
            locfiles = files
        })
        // Loop over object keys (multiple attachments)
        locfiles.forEach((attachment: any) => {
            const NAME = attachment.filename || 'NA'
            // new URL
            const URL = `${api('v1/uploads/publicfile')}/${attachment.id}?redirect=true`
            const txtAttachment = `<p class="attachment">Attachment: <a target="blank" href="${URL}" title="${NAME}">${NAME}</a></p>`
            text += txtAttachment
        })
    }
    const returnedQuery = await generalQueries.createUpdate(data.itemId, text)
    return returnedQuery
}

export const getCommentText = (comment: any): string => {
    const el = document.createElement('div')
    el.innerHTML = comment

    // // Continue processing nodes
    let returnedComment = ''

    const processNode = (node: ChildNode, listType?: 'ol' | 'ul', isFirstItem = false, isLastItem = false) => {
        if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement
            if (element.tagName === 'P') {
                returnedComment += `${element.innerHTML}\n`
            } else if (element.tagName === 'LI') {
                if (isFirstItem) {
                    returnedComment += `\n`
                }

                if (listType === 'ol') {
                    const index = Array.prototype.indexOf.call(element.parentNode?.children, element) + 1
                    returnedComment += `${index}. ${element.innerHTML}\n`
                } else if (listType === 'ul') {
                    returnedComment += `• ${element.innerHTML}\n`
                }

                if (isLastItem) {
                    returnedComment += `\n`
                }
            } else if (element.tagName === 'OL' || element.tagName === 'UL') {
                const currentListType = element.tagName.toLowerCase() as 'ol' | 'ul'
                const children = Array.from(element.childNodes).filter(
                    (child) => child.nodeType === Node.ELEMENT_NODE && (child as HTMLElement).tagName === 'LI',
                )

                children.forEach((childNode, index) =>
                    processNode(
                        childNode,
                        currentListType,
                        index === 0, // isFirstItem: true for the first <li>
                        index === children.length - 1, // isLastItem: true for the last <li>
                    ),
                )
            } else {
                element.childNodes.forEach((childNode) => processNode(childNode))
            }
        }
    }

    if (el) {
        const isCreatedInMlink = el.querySelectorAll('p.claim-comment').length > 0

        if (isCreatedInMlink) {
            const description = el.querySelector('p.description')
            if (description) {
                returnedComment += `${description.innerHTML}\n`
            }
            const claimComment = el.querySelector('p.claim-comment')
            if (claimComment) {
                returnedComment += `${claimComment.innerHTML}\n`
            }
        } else {
            // Sometimes in monday, the inner elements are not wrapped in a <p> tag for some reason, and if they are not wrapped, we wrap them to properly execute the following logic
            const firstChildIsP = el.firstElementChild?.tagName === 'P'
            const onlyChildIsP = el.childNodes.length === 1 && firstChildIsP
            // If there's no <p> wrapping all inner elements, wrap them
            if (!onlyChildIsP) {
                // Create a new <p> element
                const p = document.createElement('p')

                // Move all child nodes into the new <p>
                while (el.firstChild) {
                    p.appendChild(el.firstChild)
                }

                // Append the <p> to the `el`
                el.appendChild(p)
            }
            el.childNodes.forEach((node) => processNode(node))
        }

        // Replace <br> with newline, &nbsp; with a space
        returnedComment = returnedComment
            .replace(/<br\s*\/?>/gi, '\n')
            .replace(/&nbsp;/g, ' ')
            // Replace multiple spaces with a single space, but keep newlines
            .replace(/ +/g, ' ')

        // Remove remaining HTML tags and [public] tag
        returnedComment = returnedComment.replace(/\[public\]|<\/?[^>]+>/g, '').trim()
    } else {
        returnedComment = comment
    }
    return getbackGreaterOrLessThan(returnedComment)
}
export const extractAttachments = (comment: any): IFileLinkObject[] => {
    const el = document.createElement('div')
    el.innerHTML = comment
    const attachments: IFileLinkObject[] = []

    // For anchor tags
    if (el) {
        el.querySelectorAll('a').forEach((a) => {
            if (!isCreatedInMonday(a)) {
                attachments.push({
                    url: a.getAttribute('href') || '',
                    title: a.innerHTML,
                })
            } else {
                attachments.push({
                    url: `${mondayFileFetchUrl}${a.href}` as string,
                    title: decodeURIComponent(a.title),
                })
            }
        })

        // For image tags
        el.querySelectorAll('img').forEach((img) => {
            if (img && 'src') {
                // if img comes from monday
                if (isCreatedInMonday(img)) {
                    if (
                        (img.src.length > 0 && img.getAttribute('src')?.includes('media2.giphy')) ||
                        img.getAttribute('src')?.includes('media0.giphy')
                    ) {
                        // If monday gif
                    } else {
                        attachments.push({
                            url: `${mondayFileFetchUrl}${img.src}` as string,
                            title: decodeURIComponent(img.src.split('/')[img.src.split('/').length - 1]),
                        })
                    }
                }
            }
        })
    }
    return attachments
}

export const attachmentCount = (claimItem: IClaimResponse): number => {
    let attachmentCount = 0
    for (let i = 0; i < claimItem.updates.length; i++) {
        attachmentCount += extractAttachments(claimItem.updates[i].body).length
    }
    return attachmentCount
}

export const isCreatedInMonday = (element: HTMLAnchorElement | HTMLImageElement): boolean => {
    if ('href' in element) {
        return !(
            !element.href.includes('backend.m-link.no') ||
            !element.href.includes('backend.staging.m-link.no') ||
            !element.href.includes('backend.dev.m-link.no') ||
            !element.href.includes('localhost:8000/api/v1/uploads/publicfile')
        )
    }
    if ('src' in element) {
        return (
            !element.src.includes('backend.m-link.no') ||
            !element.src.includes('backend.staging.m-link.no') ||
            !element.src.includes('backend.dev.m-link.no') ||
            element.src.includes('localhost:8000/api/v1/uploads/publicfile')
        )
    }
    return false
}
const getbackGreaterOrLessThan = (str: string) => {
    return str.replace(/&([^;]+);/g, (entity, match) => {
        switch (match) {
            case 'gt':
                return '>'
            case 'lt':
                return '<'
            // Add more cases as needed for other HTML entities
            default:
                return entity
        }
    })
}

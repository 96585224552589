import axios from 'axios'

import { api } from './ApiSettings'
import http from './http'

import type { IPaginatedResponse, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { ISearchResponseEquipment } from '@/modules/CORE/search/interfaces/ISearch'
import type {
    EquipmentHistory,
    IClassification,
    IClassificationCode,
    IClassificationCodeCreate,
    IClassificationLanguageCode,
    IClassificationLanguageObject,
    IClassificationLanguageOld,
    IClassificationNamePatch,
    IClassificationNames,
    IClassificationNamesBase,
    IClassificationSystem,
    IClassificationSystemObject,
    IEquipment,
    IEquipmentCreate,
    IEquipmentSimple,
    IEquipmentV4,
} from '@/modules/MAINTENANCE/equipment/interfaces/IEquipment'
import type { IRemarksEquipmentListElement } from '@/modules/YARD/remarks/interfaces/IRemarks'
import type { AxiosResponse } from 'axios'
import type { IMCDropdownMultipleOption } from 'moen-components/dist/dropdowns/IMCDropdownMultiple'

import { assert } from '@/utils'

export default {
    createEquipment: async (equipment: IEquipmentCreate): Promise<UUIDv4 | undefined> => {
        const url = api('v1/equipment/')
        let response: AxiosResponse | undefined
        try {
            response = await http.post(url, JSON.stringify(equipment))
            if (response?.status === 201) {
                const id = (await response.data) as UUIDv4
                return id
            }
        } catch (e: any) {
            console.error('Failed to create equipment.', e)
        }

        return undefined
    },
    fetchSimpleEquipmentListByFacilityId: async (facilityId: UUIDv4): Promise<IEquipmentSimple[]> => {
        const url = api(`v1/facility/${facilityId}/equipment-simple/`)
        let response: Response | undefined
        let equipment: IEquipmentSimple[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                equipment = await response.json()
            }
        } catch (e) {
            throw new Error(`Could not fetch equipment due to error: ${e}`)
        }
        return equipment
    },

    searchEquipmentByFacilityIds: async (
        company_id: UUIDv4,
        facilityIds: any[],
        searchText: any,
        classificationLanguage: IClassificationLanguageOld,
        force_normal_search: boolean,
    ): Promise<ISearchResponseEquipment[] | undefined> => {
        // Force normal search for non-superusers to see "normal" results
        const url = api('v1/equipment-search-in-facilities')
        let response: AxiosResponse | undefined
        let equipment: any[] = []
        try {
            response = await http.post(url, {
                facility_ids: facilityIds,
                search: searchText,
                company_id,
                classification_language: classificationLanguage,
                force_normal_search,
            })
            if (response.status === 200) {
                equipment = response.data
            }
        } catch (e: any) {
            console.error('Failed to search equipment for facilities.', e)
        }

        return equipment
    },
    fetchEquipmentByEquipmentId: async (equipmentId: UUIDv4): Promise<IEquipmentV4 | undefined> => {
        const url = api(`v4/equipment/${equipmentId}/`)
        const errorMessage = `Failed to fetch equipment ${equipmentId}.`
        let response: Response | undefined
        let equipment: IEquipmentV4 | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                equipment = await response.json()
            }
        } catch (e: any) {
            console.error(errorMessage, e)
        }
        return equipment
    },

    patchEquipment: async (equipment: any) => {
        const url = api(`v1/equipment/${equipment.id}/`)
        let response: AxiosResponse | undefined
        try {
            response = await http.patch(url, equipment)
            return response
        } catch (error: any) {
            throw new Error(error.response?.data || error.message)
        }
    },
    fetchEquipmentPaginated: async (
        facilityId: UUIDv4,
        search: string,
        page: number,
        pageSize: number,
        sort: string,
        dir: string,
    ): Promise<any> => {
        const url = api(
            `v3/facilities/${facilityId}/equipment/pagination/?search=${search}&page=${page}&page_size=${pageSize}&sort=${sort}&dir=${dir}`,
        )
        let response: any = null
        let equipment: any = null
        try {
            response = await http.get(url)
            if (response.ok) {
                equipment = await response.json()
            }
        } catch (e: any) {
            console.error(`Failed to get equipment for ${facilityId}.`, e)
        }
        return equipment
    },
    fetchEquipmentDropdownByFacilityId: async (facilityId: UUIDv4, search: string): Promise<IMCDropdownMultipleOption[]> => {
        assert(facilityId !== undefined, 'facilityId is required')
        const url = api(`v4/facility/${facilityId}/equipment/dropdown?search=${search}`)
        const errorMessage = 'Failed to get equipment dropdown list'

        try {
            const response = await http.get(url)
            if (!response.ok) {
                throw new Error(errorMessage)
            }
            const documents: IMCDropdownMultipleOption[] = await response.json()
            return documents
        } catch (error) {
            console.error(errorMessage, error)
            return []
        }
    },
    fetchEquipmentPaginatedByFacilityId: async (
        facilityId: string,
        page: number,
        pageSize: number,
        direction: string,
        sortBy: string,
        searchText: string,
        classificationCode?: string, // Kan ta med classifikasjonskode for å filtrere på denne
    ): Promise<IPaginatedResponse | undefined> => {
        if (!facilityId) return
        let url = api(
            `v4/facilities/${facilityId}/equipment/pagination/?search=${searchText}&page=${page}&page_size=${pageSize}&sort=${sortBy}&dir=${direction}`,
        )

        // Filtrer på classifikasjonskode
        if (classificationCode) {
            url += `&classification_code=${classificationCode}`
        }

        const errorMessage = 'Failed to get equipment list'
        let response: Response | undefined
        let equipment: IPaginatedResponse | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                equipment = await response.json()
            }
        } catch (e: any) {
            console.error(errorMessage, e)
        }

        return equipment as IPaginatedResponse
    },
    // Lagt til for EquipmentBulkEdit
    fetchEquipmentV1: async (facilityId: string): Promise<IEquipment[] | undefined> => {
        if (!facilityId) return
        const url = api(`v1/facilities/${facilityId}/equipment/`)
        const errorMessage = 'Failed to get equipment list'
        let response: Response | undefined
        let equipment: IEquipment[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                equipment = await response.json()
            }
        } catch (e: any) {
            console.error(errorMessage, e)
        }

        return equipment
    },

    // classification systems
    fetchClassifications: async (
        classificationSystem: IClassificationSystem,
        classificationLanguage: IClassificationLanguageOld,
    ): Promise<IClassification[]> => {
        const url = api(`v1/classifications/${classificationSystem}/${classificationLanguage}/`)
        let response: Response | undefined
        let classifications: IClassification[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                classifications = await response.json()
            }
        } catch (e: any) {
            console.error(`Failed to get classifications for ${classificationSystem}.`, e)
        }
        return classifications
    },

    fetchSfiByFacilityId: async (facilityId: UUIDv4): Promise<IClassification[]> => {
        const url = api(`v4/facility/${facilityId}/sfi/`)
        let response: Response | undefined
        let classifications: IClassification[] = []
        try {
            response = await http.get(url)
            if (response.ok) {
                classifications = await response.json()
            }
        } catch (e: any) {
            console.error(`Failed to get classifications for ${facilityId}.`, e)
        }
        return classifications
    },

    deleteEquipment: async (equipmentId: UUIDv4): Promise<any> => {
        const url = api(`v1/equipment/${equipmentId}/`)
        try {
            const response = await http.delete(url)
            if (response.status === 200) {
                return true
            } else {
                return false
            }
        } catch (e: any) {
            console.error(`Failed to delete equipment ${equipmentId}.`, e)
            return false
        }
    },

    getEquipmentHistory: async (equipmentId: UUIDv4): Promise<EquipmentHistory[]> => {
        const url = api(`v2/historylog/equipment/${equipmentId}/`)
        try {
            const response = await http.get(url)
            if (response.ok) {
                return await response.json()
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve logs for equipment:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    // Classifications http
    fetchClassificationsSystems: async (): Promise<IClassificationSystemObject[]> => {
        const url = api('v4/moen-classification-system')
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return resp
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classification systems:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    fetchAvailableClassificationLanguages: async (): Promise<IClassificationLanguageObject[]> => {
        const url = api('v4/moen-classification-language')
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return resp
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classification languages:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    createMoenClassificationName: async (payload: IClassificationNamesBase): Promise<IClassificationNames | null> => {
        const url = api('v4/moen-classification-names/')
        try {
            const response = await http.post(url, JSON.stringify(payload))
            if (response.status) {
                return await response.data
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return null
            }
        } catch (error) {
            console.error('Failed to create classification name:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    fetchMoenClassificationCodes: async (): Promise<IClassificationCode[]> => {
        const url = api('v4/moen-classification')
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return resp
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classification codes:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    createMoenClassificationCode: async (code: IClassificationCodeCreate): Promise<IClassificationCode | null> => {
        const url = api('v4/moen-classification/')
        try {
            const response = await http.post(url, JSON.stringify(code))
            if (response.status) {
                return await response.data
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return null
            }
        } catch (error) {
            console.error('Failed to create classification code:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    fetchAvailableClassificationNames: async (): Promise<IClassificationNames[]> => {
        const url = api('v4/moen-classification-names')
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return resp
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classification names:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    // ! BROKEN API
    // fetchClassificationsByLocaleAndSystem: async (
    //     locale: IClassificationLanguageCode,
    //     system: string = 'sfi',
    // ): Promise<IClassification[]> => {
    //     const url = api(`v4/classifications/${system}/${locale}/`)
    //     try {
    //         const response = await http.get(url)
    //         if (response.ok) {
    //             const resp = await response.json()
    //             return Object.values(resp)
    //         } else {
    //             console.error(`Server responded with status: ${response.status}`)
    //             return []
    //         }
    //     } catch (error) {
    //         console.error('Failed to retrieve classifications:', error)
    //         if (axios.isAxiosError(error) && error.response) {
    //             throw new Error(`Error: ${error.response.status}`)
    //         } else {
    //             throw error
    //         }
    //     }
    // },
    fetchFlatClassificationsByLocaleAndSystem: async (
        locale: IClassificationLanguageCode,
        system: string = 'sfi',
    ): Promise<IClassification[]> => {
        const url = api(`v4/classifications/${system}/${locale}/list`)
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return Object.values(resp)
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classifications:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    fetchClassificationsByLocaleAndSystemAndFacility: async (
        facility_id: UUIDv4,
        system: IClassificationSystem,
        language: IClassificationLanguageCode,
    ): Promise<IClassification[]> => {
        const url = api(`v4/classifications/${system}/${language}/facility/${facility_id}`)
        try {
            const response = await http.get(url)
            if (response.ok) {
                const resp = await response.json()
                return Object.values(resp)
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return []
            }
        } catch (error) {
            console.error('Failed to retrieve classifications:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    partlyUpdateClassification: async (updatedClassification: IClassificationNamePatch): Promise<boolean> => {
        const url = api(`v4/moen-classification-names/${updatedClassification.id}/`)
        try {
            await http.patch(url, JSON.stringify(updatedClassification))
        } catch (error) {
            console.error('Failed to update classification:', error)
            return false
        }
        return true
    },
    getEquipmentHistoryCount: async (equipmentId: UUIDv4): Promise<number> => {
        const url = api(`v4/equipment/${equipmentId}/history_count/`)
        try {
            const response = await http.get(url)
            if (response.ok) {
                return await response.json()
            } else {
                console.error(`Server responded with status: ${response.status}`)
                return 0
            }
        } catch (error) {
            console.error('Failed to retrieve logs for equipment:', error)
            if (axios.isAxiosError(error) && error.response) {
                throw new Error(`Error: ${error.response.status}`)
            } else {
                throw error
            }
        }
    },
    getClassificationCodesStringByEquipmentIds: async (equipmentIds: UUIDv4[]): Promise<string> => {
        const url = api('v4/equipment/classification-codes-for-equipment/')
        let classification_codes: string = ''
        try {
            const response = await http.post(url, JSON.stringify({ equipment_ids: equipmentIds }))

            if (response?.status === 200) {
                classification_codes = await response.data.codes
            }
        } catch (e: any) {
            console.error('Failed to create equipment.', e)
            return ''
        }

        return classification_codes
    },
    getRelatedRemarksToEquipment: async (equipmentId: UUIDv4): Promise<IRemarksEquipmentListElement[]> => {
        const url = api(`v4/equipment/${equipmentId}/remarks/`)
        let returnedRemarks: IRemarksEquipmentListElement[] = []
        try {
            const response = await http.get(url)
            if (response.ok) {
                returnedRemarks = await response.json()
            }
        } catch (e: any) {
            console.error(`Failed to get remarks for equipment ${equipmentId}.`, e)
        }
        return returnedRemarks
    },
    getRelatedRemarksToEquipmentCount: async (equipmentId: UUIDv4): Promise<number> => {
        const url = api(`v4/equipment/${equipmentId}/remarks/count/`)
        let returnedRemarks: number = 0
        try {
            const response = await http.get(url)
            if (response.ok) {
                returnedRemarks = await response.json()
            }
        } catch (e: any) {
            console.error(`Failed to get remarks for equipment ${equipmentId}.`, e)
        }
        return returnedRemarks
    },
}

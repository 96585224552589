import { api } from './ApiSettings'
import http from './http'

import type { IPaginatedResponse, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IUser } from '@/modules/CORE/interfaces/IUser'
import type {
    IWorkorderChatPost,
    IWorkorderCorrective,
    IWorkorderCreate,
    IWorkorderPreventive,
    IWorkorderTodo,
} from '@/modules/MAINTENANCE/workorders/interfaces/IWorkorder'
import type { AxiosResponse } from 'axios'

export default {
    fetchOpenWorkOrdersByFacility: async (facilityId: UUIDv4, type: 'corrective' | 'preventive' | '' = ''): Promise<IWorkorderTodo[]> => {
        let tasks: IWorkorderTodo[] = []

        let queryParams = ''
        if (type) {
            queryParams = `?type=${type}`
        }

        const url = api(`v1/facilities/${facilityId}/open-work-orders${queryParams}`)
        let response: Response | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                tasks = await response.json()
            }
        } catch (err) {
            console.error('Failed to get open-work-orders by facility', err)
        }
        return tasks
    },
    fetchWorkordersPaginatedV4: async (facilityId: UUIDv4, queryParameters: string): Promise<IPaginatedResponse | undefined> => {
        const url = api(`v4/facilities/${facilityId}/workorders/${queryParameters}`)
        try {
            const response = await http.get(url)

            if (!response.ok) {
                throw new Error(`API returned ${response.status}: ${response.statusText}`)
            }

            const paginatedResponse: IPaginatedResponse = await response.json()

            return paginatedResponse
        } catch (err) {
            console.error('Error fetching workorders:', err)
            return undefined
        }
    },
    fetchWorkordersForUserV4: async (queryParameters: string): Promise<IPaginatedResponse | undefined> => {
        const url = api(`v4/workorders-for-user/${queryParameters}`)

        try {
            const response = await http.get(url)

            if (!response.ok) {
                throw new Error(`API returned ${response.status}: ${response.statusText}`)
            }

            const paginatedResponse: IPaginatedResponse = await response.json()

            return paginatedResponse
        } catch (err) {
            console.error('Error fetching workorders:', err)
            return undefined
        }
    },
    fetchWorkordersPaginatedV4Count: async (facilityId: UUIDv4, queryParameters: string): Promise<number> => {
        const url = api(`v4/facilities/${facilityId}/workorders/${queryParameters}&count_only=true`)

        try {
            const response = await http.get(url)

            if (!response.ok) {
                throw new Error(`API returned ${response.status}: ${response.statusText}`)
            }

            return response.json()
        } catch (err) {
            console.error('Error fetching workorders count:', err)
            return 0
        }
    },
    fetchWorkorderById: async (workorderId: UUIDv4): Promise<IWorkorderPreventive | IWorkorderCorrective | undefined> => {
        const url = api(`v1/workorders/${workorderId}/`)
        const errorMessage = 'Failed to get workorder'
        let response: Response | undefined
        let workorder: IWorkorderPreventive | IWorkorderCorrective | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                workorder = await response.json()
                return workorder
            }
        } catch (err) {
            console.error(errorMessage, err)
            return undefined
        }
    },
    createWorkorder: async (workorder: IWorkorderCreate): Promise<IWorkorderPreventive | IWorkorderCorrective | undefined> => {
        const url = api('v1/workorders/')

        try {
            const response = await http.post(url, workorder)
            if (response.status === 201 || response.statusText === 'Created') {
                return response.data
            }
        } catch (err) {
            console.error('Error creating workorder:', err)
        }

        return undefined
    },
    updateWorkorder: async (workorder: any): Promise<AxiosResponse<Partial<IWorkorderCorrective | IWorkorderPreventive>>> => {
        const workorderId = workorder.id
        const url = api(`v2/workorders/${workorderId}/update-workorder/`)
        const errorMessage = 'Failed to update workorder: '
        try {
            const response = await http.patch(url, workorder)
            return response
        } catch (err) {
            console.error(errorMessage, err)
            throw err // You might want to rethrow the error to handle it upstream
        }
    },
    fetchResponsiblesByCompanyId: async (companyId: UUIDv4): Promise<IUser[] | undefined> => {
        const url = api(`v1/companies/${companyId}/manning`)
        const errorMessage = 'Failed to get responsibles'
        let response: Response | undefined
        let responsibles: IUser[] | undefined
        try {
            response = await http.get(url)
            if (response.ok) {
                responsibles = await response.json()
                return responsibles
            }
        } catch (err) {
            console.error(errorMessage, err)
            return undefined
        }
    },
    updateWorkorderChecked: async (workorderId: UUIDv4, checkedTasks: UUIDv4[]): Promise<AxiosResponse> => {
        const url = api(`v1/workorders/${workorderId}/update-checked/`)
        return await http.patch(url, checkedTasks)
    },
    deleteWorkorder: async (workorderId: UUIDv4): Promise<AxiosResponse> => {
        const url = api(`v1/workorders/${workorderId}/`)
        const errorMessage = `Failed to delete workorder ${workorderId}.`

        try {
            return await http.delete(url)
        } catch (e) {
            console.error(errorMessage, e)
            throw e
        }
    },
    signAndCloseWorkorder: async (workorder: IWorkorderPreventive | IWorkorderCorrective, comment: string = ''): Promise<AxiosResponse> => {
        const workorderId = workorder.id
        const url = api(`v1/workorders/${workorderId}/sign/`)
        const facilityId = workorder.facility
        const signature = { comment, facility: facilityId }
        const signedWorkorder = { ...workorder, signature }

        return await http.put(url, signedWorkorder)
    },
    fetchWorkordersByEquipmentPaginated: async (equipmentId: UUIDv4, queryParameters: string): Promise<IPaginatedResponse | undefined> => {
        const url = api(`v4/equipment/${equipmentId}/workorders/paginated/${queryParameters}`)
        try {
            const response = await http.get(url)

            if (!response.ok) {
                throw new Error(`API returned ${response.status}: ${response.statusText}`)
            }

            const paginatedResponse: IPaginatedResponse = await response.json()

            return paginatedResponse
        } catch (err) {
            console.error('Error fetching workorders:', err)
            return undefined
        }
    },
    searchWorkorderByFacilityIds: async (facilityIds: any[], searchText: any): Promise<any[] | undefined> => {
        const url = api('v1/workorders-search-in-facilities')
        let response: AxiosResponse | undefined
        let workorders: any[] = []
        try {
            response = await http.post(url, { facility_ids: facilityIds, search: searchText })
            if (response.status === 200) {
                workorders = await response.data
            }
        } catch (e) {
            console.error('Failed to search documents for facilities.', e)
        }

        return workorders
    },
    createWorkorderChat: async (workorderId: UUIDv4, facilityId: UUIDv4, chat: IWorkorderChatPost): Promise<any> => {
        const url = api(`v4/workorders/${workorderId}/conversations/chats/`)
        const errorMessage = 'Failed to create workorder chat.'
        let response = null
        try {
            response = await http.post(url, chat)

            if (response.status === 201) {
                chat = response.data
            }
        } catch (e) {
            console.error(errorMessage, e)
        }

        return { response, chat }
    },
    editWorkorderChat: async (workorderId: UUIDv4, chatId: UUIDv4, updatedChat: Partial<IWorkorderChatPost>): Promise<any> => {
        const url = api(`v4/workorders/${workorderId}/conversations/chats/${chatId}/`)
        let response: any = null
        try {
            response = await http.patch(url, updatedChat)
        } catch (e) {
            console.error('Failed to edit workorder chat.', e)
        }
        return response?.data
    },
    deleteWorkorderChat: async (workorderId: UUIDv4, facilityId: UUIDv4, chatId: UUIDv4): Promise<any> => {
        const url = api(`v4/workorders/${workorderId}/conversations/chats/${chatId}/`)
        let response = null
        try {
            response = await http.delete(url)
        } catch (e) {
            console.error('Failed to delete workorder chat.', e)
        }
        return response
    },
}

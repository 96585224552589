<template>
    <div class="login-container">
        <form class="auth-forms-container" @submit.prevent="() => {}" @keydown.enter.prevent="() => {}">
            <transition name="slide-fade" mode="out-in">
                <div v-if="!toggleForgotPasswordMode" style="width: 100%">
                    <h2 class="auth-title">
                        {{ t('auth.login') }}
                    </h2>
                    <div class="login-form">
                        <div class="auth-input-field">
                            <MCTextInput
                                id="login-email"
                                v-model="email"
                                :errorMessage="t('auth.error.email-invalid')"
                                :hasError="!isEmailValid"
                                :label="t('auth.email')"
                                autocomplete="username"
                                autofocus
                                icon="account-circle"
                                iconPlacement="right"
                                @blur="validateEmail(emailWithNoTrailingSpaces)"
                                @focus="clearEmailError"
                                @keyup.enter.prevent="loginLocal"
                            />
                        </div>
                        <div class="auth-input-field reset-password">
                            <MCTextInput
                                id="login-password"
                                v-model="password"
                                :label="t('auth.password')"
                                type="password"
                                @keyup.enter.prevent="loginLocal"
                            />
                            <div
                                class="forgot-password-button button-unstyled"
                                :title="t('auth.reset-password.title')"
                                style="margin-top: 1.38rem; margin-bottom: 1rem; font-size: 0.8rem"
                                @click.prevent="toggleForgotPassword()"
                                @keydown.enter.prevent="toggleForgotPassword()"
                            >
                                {{ t('auth.reset-password.title') }}
                            </div>
                        </div>
                        <div class="auth-input-field" style="margin-top: 1.38rem">
                            <MCButton
                                id="login-button"
                                :label="t('auth.login')"
                                :disabled="disableSubmitButton || loginInProgress"
                                :loading="loginInProgress"
                                :loadingText="t('auth.logging-in')"
                                @click="loginLocal"
                            />
                        </div>
                    </div>
                </div>
                <div v-else class="forgot-password-form">
                    <transition name="fade" mode="out-in">
                        <div v-if="!submitSuccess" key="reset-form">
                            <h2 class="auth-title">
                                {{ t('auth.reset-password.title') }}
                            </h2>
                            <p class="body-body">
                                {{ t('auth.reset-password.body') }}
                            </p>
                        </div>
                        <div v-else key="success-message">
                            <h2>{{ t('auth.reset-password.email-sent') }}</h2>
                            <p class="body-body">
                                {{ emailSentOK }}
                            </p>
                        </div>
                    </transition>

                    <div class="auth-input-field reset-password">
                        <MCTextInput
                            v-model="email"
                            :errorMessage="t('auth.error.email-invalid')"
                            :hasError="!isEmailValid"
                            :label="t('auth.email')"
                            @blur="validateEmail(emailWithNoTrailingSpaces)"
                            @focus="clearEmailError"
                            @keyup.enter.prevent="resetPassword"
                        />
                    </div>
                    <div class="back-to-login">
                        <MCIcon class="reset-password-button" icon="arrow-left" @click.prevent="toggleForgotPassword()" />
                        <button
                            class="button-unstyled"
                            :title="t('auth.reset-password.title')"
                            style="margin-top: 1rem; margin-bottom: 1rem"
                            @click.prevent="toggleForgotPassword()"
                            @keydown.enter.prevent="toggleForgotPassword()"
                        >
                            {{ t('auth.back-to-login') }}
                        </button>
                    </div>
                    <MCButton
                        :disabled="!isEmailValid || submitInProgress"
                        :label="t('auth.reset-password.title')"
                        type="primary"
                        :loading="submitInProgress"
                        :loadingText="t('auth.reset-password.sending-email')"
                        @click="resetPassword"
                    />
                </div>
            </transition>
        </form>
        <div class="bottom-content">
            <div id="moen-marin-logo-container">
                <img src="/MM_blaa_sentrert.png" alt="" height="100" />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { AxiosError } from 'axios'
import { computed, onBeforeMount, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { toast } from 'vue3-toastify'

import { useGlobalStore } from '@/stores/global'
import authApi from '@/api/auth'

import type { AxiosResponse } from 'axios'

import { isValidEmail } from '@/utils'

const { t } = useI18n()
const route = useRoute()
const router = useRouter()
const store = useGlobalStore()

const email = ref<string>('')
const password = ref<string>('')

const toggleForgotPasswordMode = ref<boolean>(false)

const isEmailValid = ref<boolean>(true)

const emailWithNoTrailingSpaces = computed(() => {
    return email.value.trim()
})

onBeforeMount(() => {
    // check if user is already logged in
    if (store.currentUser) router.push('/home')

    // if query parameter "forgot" is present, show forgot password form
    const queryParams = route.query

    if (queryParams.forgot) {
        toggleForgotPasswordMode.value = true
    }
})

const validateEmail = (email: string): void => {
    if (!email) return
    if (email.length === 0) {
        isEmailValid.value = true
    } else {
        isEmailValid.value = isValidEmail(email)
    }
}

const clearEmailError = (): void => {
    isEmailValid.value = true
}

const disableSubmitButton = computed(() => {
    if (!isEmailValid.value) {
        return true
    }
    return !email.value || !password.value
})

const loginInProgress = ref<boolean>(false)

const errorToast = (statusCode: number): void => {
    switch (statusCode) {
        case 401:
            toast.error(t('auth.error.invalid-credentials'))
            break
        case 429:
            toast.error(t('auth.error.too-many-requests'))
            break
        case 400:
            toast.error(t('auth.error.invalid-credentials'))
            break
        default:
            toast.error(t('auth.error.could-not-login'))
    }
}

const loginLocal = async (): Promise<void> => {
    validateEmail(emailWithNoTrailingSpaces.value)
    if (!isEmailValid.value || !emailWithNoTrailingSpaces.value || !password.value) {
        return
    }
    loginInProgress.value = true
    try {
        const response: AxiosResponse = await authApi.login(emailWithNoTrailingSpaces.value, password.value)
        if (response.status === 200) {
            store.loginActionsPostAuthentication()
            router.push('/home')
        }
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            const statusCode: number = error.response.status
            errorToast(statusCode)
        } else {
            toast.error(t('auth.error.network-issue'))
        }
        loginInProgress.value = false
    }
}

const toggleForgotPassword = (): void => {
    if (toggleForgotPasswordMode.value) submitSuccess.value = false
    toggleForgotPasswordMode.value = !toggleForgotPasswordMode.value
}

const emailSentOK = computed(() => {
    return t('auth.reset-password.email-sent-body', { email: email.value })
})

const submitInProgress = ref<boolean>(false)
const submitSuccess = ref<boolean>(false)

const resetPassword = async (): Promise<void> => {
    if (!isEmailValid.value) {
        return
    }
    submitInProgress.value = true
    try {
        const response: AxiosResponse = await authApi.resetPassword(email.value)
        if (response.status === 200) {
            toast.success(t('auth.reset-password.email-sent'))
            submitSuccess.value = true
            setTimeout(() => {
                toggleForgotPasswordMode.value = false
                submitSuccess.value = false
            }, 2000)
        }
    } catch (error) {
        if (error instanceof AxiosError && error.response) {
            submitSuccess.value = false
            toast.error(t('auth.reset-password.password-reset-error'))
        } else {
            toast.error(t('auth.error.network-issue'))
        }
    } finally {
        submitInProgress.value = false
    }
}
</script>

<style lang="scss">
@import '@/scss/colors.scss';
@import '@/scss/breakpoints.scss';
.login-container {
    height: 100dvh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: $mlink-secondary;
}
.auth-forms-container {
    display: flex;
    flex-direction: column;
    height: 30rem;
    width: 24rem;
    padding: 1.38rem;
    gap: 1.38rem;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    background: var(--mLINK-neutrals-gray1, #f8f9f9);
    border-radius: 0.625rem;
}
.auth-input-field {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    &.reset-password {
        margin-top: 1.38rem;
        align-items: flex-start;
    }
}

.forgot-password-button {
    margin-top: 0.5rem;
    color: $mlink-primary;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
}
.login-form,
.forgot-password-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    align-items: center;
    // justify-content: center;
    flex: 1;
}
.back-to-login {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    gap: 0.5rem;
    cursor: pointer;
    margin-top: 1.38rem;
}
.bottom-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 1rem;
}
@include breakpoint-mobile {
    .auth-forms-container {
        height: 100dvh;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
}

// ANIMATIONS
.slide-fade-enter-active,
.slide-fade-leave-active {
    transition: all 0.3s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
    transform: translateY(2rem);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>

<template>
    <div v-if="showInfoBanner" id="global-notification-message">
        <MCInfoBanner :type="infoBannerType" :label="infoBannerText" dismissible />
    </div>

    <!-- AUTHENTICATED USER VIEW -->
    <SideNavigation v-if="userIsAuthenticated && !isInviteAcceptPage && !isResetPasswordPage" ref="sidebarsRef" />
    <main v-if="userIsAuthenticated" id="authenticated-content">
        <MCSpinner v-if="loadingCompany" size="medium" style="margin-top: 10rem" />
        <RouterView v-else />
    </main>

    <!-- UNAUTHORISED USER VIEW -->
    <main v-if="!userIsAuthenticated && delayedToPreventDualMount" id="unauthenticated-content">
        <RouterView />
    </main>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { RouterView, useRouter } from 'vue-router'

import SideNavigation from '@/modules/CORE/navigation/SideNavigation.vue'
import { fetchOperationalMessagesFromMonday } from '@/modules/SUPPORT/graphql/utils'
import { useGlobalStore } from '@/stores/global.js'
import authApi from '@/api/auth'

import type { TBannerType, TSeverity, UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IUser } from '@/modules/CORE/interfaces/IUser'

import { getRouteContext } from '@/utils'

const store = useGlobalStore()
const sidebarsRef = ref()
const delayedToPreventDualMount = ref<boolean>(false)

const router = useRouter()

const showInfoBanner = ref<boolean>(false)
const infoBannerType = ref<TBannerType>()
const infoBannerText = ref<string>('')

const isInviteAcceptPage = computed(() => router.currentRoute.value.name === '/accept-invitation')
const isResetPasswordPage = computed(() => router.currentRoute.value.name === '/reset-password')
const userIsAuthenticated = computed(() => store.currentUser ?? false)
const loadingCompany = computed(() => store.currentCompany === null)

const initialFunction = async () => {
    // if user is on login page and is authenticated, then redirect to home and load proper context
    if (window.location.href.includes('/login')) {
        const user: IUser | null = await store.fetchUser()
        if (user) {
            router.push('/home')
            await contextHandler()
            return
        }
    }

    const IGNORED_ROUTES: string[] = ['/login', '/logout', '/accept-invitation', '/reset-password']

    const ignoreRoute = (url: string) => {
        return IGNORED_ROUTES.some((route) => url.includes(route))
    }

    const currentURL = window.location.href

    if (ignoreRoute(currentURL)) {
        return
    }

    await contextHandler()

    await store.setupNotifications()
}

const contextHandler = async () => {
    // ensure we have user
    if (!store.currentUser) {
        const user: IUser | null = await store.fetchUser()
        if (!user) {
            router.push('/login')
            return
        }
    }

    const context = getRouteContext(router)

    if (context === 'facility') {
        loadFacilityContext()
    } else if (context === 'company') {
        loadCompanyContext()
    } else {
        loadNoContext()
    }
}

const loadNoContext = async () => {
    const context = await authApi.noContext()
    if (!context) {
        console.error('Could not establish no context')
        return
    }
    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}

const loadCompanyContext = async () => {
    const currentRoute = router.currentRoute.value
    const routeParams: any = currentRoute?.params
    const routeCompanyId: UUIDv4 | undefined = routeParams?.companyId

    if (!routeCompanyId) {
        console.error('Could not get companyId from route')
        return
    }

    const context = await authApi.companyContext({ companyId: routeCompanyId })

    if (!context) {
        console.error('Could not establish company context')
        return
    }

    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}

const loadFacilityContext = async () => {
    const currentRoute = router.currentRoute.value
    const routeParams: any = currentRoute?.params
    const routeFacilityId: UUIDv4 | undefined = routeParams?.facilityId

    if (!routeFacilityId) {
        console.error('Could not get facilityId from route')
        return
    }

    const context = await authApi.facilityContext({ facilityId: routeFacilityId })

    if (!context) {
        console.error('Could not establish facility context')
        return
    }

    store.setCurrentCompanyAndFacility(context.companyId, context.facilityId)
}

const handleResize = (): void => {
    const mobileBreakpoint = 800
    const tabletBreakpoint = 1200
    const tinyBreakpoint = 400

    store.isTinyScreenSize = window.innerWidth < tinyBreakpoint
    store.isMobileScreenSize = window.innerWidth < mobileBreakpoint
    store.isTabletScreenSize = window.innerWidth < tabletBreakpoint && window.innerWidth >= mobileBreakpoint
    store.isMobileOrTabletScreenSize = window.innerWidth < tabletBreakpoint
    store.isDesktopScreenSize = window.innerWidth >= tabletBreakpoint
}

const checkForOperationalMessages = async () => {
    const isProd = window.location.href.includes('https://v4.m-link.no')

    if (!isProd) {
        return
    }

    const message = await fetchOperationalMessagesFromMonday()
    if (message?.title) {
        showInfoBanner.value = true
        infoBannerType.value = fromSeverityToInfoBannerType(message.severity)
        infoBannerText.value = `${message.title}\n\n ${message.text}`
    } else {
        showInfoBanner.value = false
    }
}

const fromSeverityToInfoBannerType = (severity: TSeverity): TBannerType => {
    switch (severity) {
        case 'Red':
            return 'danger'
        case 'Yellow':
            return 'warning'
        case 'Green':
            return 'success'
        case 'Blue':
            return 'info'
        default:
            return 'info'
    }
}

const msgCheckInterval = ref<any>()

// handle user authentication
onMounted(async () => {
    await router.isReady()
    initialFunction()

    // poll monday for operational messages on app start/refresh + every 30 min
    checkForOperationalMessages()
    const min = 30
    msgCheckInterval.value = setInterval(
        () => {
            checkForOperationalMessages()
        },
        min * 60 * 1000,
    )

    // resize handler
    handleResize()
    window.addEventListener('resize', handleResize)

    setTimeout(() => {
        // in a refresh scenario or first load of the page from a link
        // the user is for a very short while unauthenticated
        // this actually triggers rending of the unauthenticated view
        // for a short while, which is not desired if the user is actually
        // being authenticated after 250 ish milliseconds, consequently we set
        // a 350 ish millisecond delay to prevent "dual mounting" of vue
        // components. This will only affect the unauthenticated view anyways.
        delayedToPreventDualMount.value = true
    }, 400)
})

onBeforeUnmount(() => {
    window.removeEventListener('resize', handleResize)

    // clear the interval
    clearInterval(msgCheckInterval.value)
})
</script>

<style lang="scss">
@import '@/scss/core.scss';
@import '@/scss/breakpoints.scss';
@import '@/scss/typography.scss';
@import '@/scss/shadows.scss';
@import '@/scss/colors.scss';
@import '@/scss/scrollbar.scss';

* {
    font-family: 'Mulish', sans-serif;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}
html {
    overflow: hidden;
}

$app-height: 100dvh;
$horizontal-margin: 1.88rem;
$vertical-margin: 1.81rem;
$mobile-head-height: 4rem;
$sidepanel-width: 5rem;
#unauthenticated-content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $app-background;
    height: 100dvh;
    @include mac-scrollbar-vertical;
    overflow-x: hidden;
}

body {
    margin: 0 auto;
    @include breakpoint-mobile {
        background-color: white;
    }
}

#global-notification-message {
    position: fixed;
    top: 2px;
    left: calc(50% - 325px);
    width: 650px;
    z-index: 1000;

    @include breakpoint-mobile {
        width: calc(80%);
        left: calc(50% - 40%);
        top: unset;
        bottom: 1rem;
    }
}

#authenticated-content {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    overflow-y: auto;
    height: 100dvh;
    background-color: $app-background;
    @include mac-scrollbar-vertical;
    overflow-x: hidden;

    @include breakpoint-mobile {
        flex-direction: column;

        width: calc(100% - 0.125rem);
        min-width: calc(100% - 0.125rem);
        max-width: calc(100% - 0.125rem);

        top: 4rem;
        height: calc(100dvh - 4rem);
    }
}

#app {
    padding-top: env(safe-area-inset-top);
    background-color: $app-background;
    margin: 0 auto;
    display: flex;
    @include breakpoint-mobile {
        flex-direction: column;

        height: $app-height;
        min-height: $app-height;
        max-height: $app-height;

        #mobile-head {
            height: $mobile-head-height;
        }

        #authenticated-content {
            flex: 1;
            width: 100%;
            height: calc(100dvh - $mobile-head-height);
            min-height: calc(100dvh - $mobile-head-height);
            max-height: calc(100dvh - $mobile-head-height);
        }
    }
    @include breakpoint-tablet {
        flex-direction: column;

        height: $app-height;
        min-height: $app-height;
        max-height: $app-height;

        #mobile-head {
            height: $mobile-head-height;
        }

        #authenticated-content {
            flex: 1;
            width: 100%;
            height: calc(100dvh - $mobile-head-height);
            min-height: calc(100dvh - $mobile-head-height);
            max-height: calc(100dvh - $mobile-head-height);
        }
    }
}

$mobile-margin: 0rem;
$mobile-padding: 0rem;

.standard-page {
    height: fit-content;
    min-height: calc(100dvh - ($horizontal-margin * 2));
    padding: $vertical-margin $horizontal-margin;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    transition: all 0.3s ease-in;
    @include breakpoint-mobile {
        width: calc(100% - (2 * $mobile-padding));
        height: calc(100dvh - $mobile-head-height - (2 * $mobile-padding));
        padding: $mobile-padding;
        margin: $mobile-margin;
    }
    @include breakpoint-tablet {
        width: calc(100% - (2 * $mobile-padding));
        height: calc(100dvh - $mobile-head-height - (2 * $mobile-padding));
        padding: $mobile-padding;
        margin: $mobile-margin;
    }

    @media (min-width: 1921px) {
        transition: all 0.3s ease-in;
        justify-content: space-around;
    }
    &.force-left {
        justify-content: flex-start;
    }
    &.full-width {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    & .dynamic-width {
        width: 66.5625rem;
        @include mac-scrollbar-vertical;
        overflow-x: hidden;
    }
}

.standard-table {
    border: 0.0625rem solid #ccc;
}

@mixin font-styles {
    font-style: normal;
}

.breadcumb {
    @include font-styles;
    color: var(#021e40);
    font-size: 0.875rem;
    font-weight: 350;
    line-height: 1.25rem;
}

.standard-form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.button-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}

.dynamic-viewport {
    padding: 0.75rem;
    @include breakpoint-mobile {
        padding-left: 0.25rem;
        padding-right: 0.25rem;

        padding-top: 0.125rem;
        padding-bottom: 0.125rem;

        height: calc(100dvh - 4rem - 0.25rem);
    }
}

.desktop-viewport {
    padding: 0.75rem;
}

.mobile-viewport {
    height: calc(100dvh - mobile-head-height);
    overflow-x: hidden;
    padding: 0.5rem;
    max-width: 100dvw;
    @media screen and (orientation: landscape) {
        max-width: 100dvw; // needs to be tested more!!
    }
    &.mobile-viewport-no-padding {
        padding: 0rem;
    }
}

.mobile-search-full-width {
    margin-top: 0.5rem;
    width: 100% !important;
}

.mobile-card-container {
    margin-top: 0.75rem;
    height: calc(100dvh - 16rem);
    overflow-y: auto;
    overflow-x: hidden;
}

.flex-center {
    @include flex-center;
}

#mobil-modal-h2-container {
    h2 {
        font-size: 4vw;
    }
}

#mobil-modal-company-detail-container {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
    p {
        margin: 0rem;
    }
    span {
        margin: 0rem;
    }
    div.icon-and-counter-container {
        margin-top: 0rem;
        margin-bottom: 0rem;
    }
}

.icon-and-counter-container {
    display: flex;
    gap: 1rem;
}

.desktop-search-and-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .mc-button {
        padding-left: 1.75rem;
        padding-right: 1.75rem;
        margin-top: 1.5rem;
    }
}

:root {
    --popper-theme-background-color: #ffffff;
    --popper-theme-background-color-hover: #ffffff;
    --popper-theme-text-color: #333333;
    --popper-theme-border-width: 0.0625rem;
    --popper-theme-border-style: solid;
    --popper-theme-border-color: #dadada;
    --popper-theme-border-radius: 0.375rem;
    --popper-theme-padding: 1.5rem;
    --popper-theme-box-shadow: 0 0.375rem 1.875rem -0.375rem rgba(0, 0, 0, 0.25);
}

.container-column-1 {
    width: 28rem;
}

.column-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h4 > span {
        color: $gray-light;
    }
}

.column-flex-list {
    padding-top: 2.125rem;
    display: flex;
    flex-direction: column;
    gap: 1.625rem;
    justify-content: flex-start;
    align-items: flex-start;
    overflow-y: auto;
    @include mac-scrollbar-vertical;
    overflow-x: hidden;
    height: calc(100dvh - 14.75rem);
}

table.borderless {
    width: calc(100% - 2rem);
    margin-left: 1.6875rem;
    margin-top: -0.75rem;
    @include breakpoint-mobile {
        width: calc(100% - 2rem);
        margin-top: 0rem;
        margin-left: 0rem;
    }
    border: none;

    tr.borderless {
        border: none;
        background-color: white !important;
        td.borderless {
            border: none;
            &:first-child {
                color: #888;
                text-align: right;
                width: 7rem;
            }
            &:nth-child(2) {
                padding: 0rem;
                padding-left: 0.5rem;
                padding-right: 0.5rem;
                width: calc(100% - 1rem);
            }
        }
    }
}

.animate__animated {
    // enter animations
    &.animate__fadeIn,
    &.animate__fadeInDownBig,
    &.animate__fadeInUpBig,
    &.animate__fadeInRight,
    &.animate__fadeInDown {
        --animate-duration: 0.375s;
        @include breakpoint-mobile {
            --animate-duration: 0.3s;
        }
        @include breakpoint-tablet {
            --animate-duration: 0.4s;
        }
    }
    // exit animations
    &.animate__fadeOut,
    &.animate__fadeOutDownBig,
    &.animate__fadeOutRight,
    &.animate__fadeOutDown {
        --animate-duration: 0.2s;
        @include breakpoint-mobile {
            --animate-duration: 0.2s;
        }
        @include breakpoint-tablet {
            --animate-duration: 0.3s;
        }
    }
    &.animate__zoomOutRight {
        --animate-duration: 0.3s;
    }
}

// Other stuff
.h4-form-header {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

// CHECKLIST STUFF
h3.checklist-header-3 {
    margin-left: 2rem;
}

h4.checklist-header-4 {
    margin-top: 1.75rem;
    margin-left: 2rem;
    margin-bottom: 1.375rem;

    &.no-wrapper-margins {
        margin: 0rem;
    }
    color: #000;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: 0.0112rem;
}

.create-rule-wrapper {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-bottom: 5rem;
}

.no-pointer-on-hover {
    &:hover {
        cursor: default !important;
    }
}

@mixin inline-edit-common {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;

    > span:hover {
        cursor: pointer;
    }

    svg.mlink-icon:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    > div > svg.mlink-icon:active {
        transform: scale(0.95);
    }
}

.metainfo {
    display: flex;
    flex-direction: column;
    gap: 0.44rem;
    height: fit-content;
    width: 12.5rem;
    padding: 0.44rem;
    max-width: 12.5rem;
    p.metatitle {
        color: $gray-light;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.375rem;
    }
    p.body-body {
        font-weight: 500;
    }
}

.upload-label-centered {
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-value-label {
    color: var(--mLINK-neutrals-gray6, #545962);
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.0075rem;
}

.form-value-text {
    color: var(--mLINK-neutrals-gray8, #22272a);
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.0088rem;
    display: block;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

// form display labels and values for inline edit components
@mixin inline-edit-common {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 0.5rem;

    > span:hover {
        cursor: pointer;
    }

    svg.mlink-icon:hover {
        cursor: pointer;
        transform: scale(1.05);
    }

    > div > svg.mlink-icon:active {
        transform: scale(0.95);
    }
}

// icon in the middle
.lc-inline-edit-icon {
    width: 10%;
}

// overriding the default styles of p.body-body
p.body-small {
    width: auto;
    color: #22272a;
    font-size: 0.875rem;
    font-weight: 400;
    letter-spacing: 0.0088rem;
}

.mc-dropdown-single-inline {
    .dropdown-list-item {
        padding-top: 0.25rem !important;
        padding-bottom: 0.125rem !important;
        height: 1.875rem !important;
    }
    .mc-dropdown-search-wrapper {
        height: 1.875rem;
        div {
            height: 1.75rem;
            div {
                > div {
                    margin-top: 0.1875rem;
                }
            }
        }
    }
}

h5.dashboard-sub-title {
    width: 100%;
    color: gray;
    font-weight: 400;
}

.dual-tiny-inputs {
    margin-top: 1rem;
    margin-bottom: 0.75rem;
    display: flex;
    gap: 1rem;
}

.native-datetime-picker {
    display: flex;
    flex-direction: column;
    label {
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 150%;
        letter-spacing: 0.00875rem;
        font-style: normal;
    }
    input {
        width: 9.5rem;
        height: 1.5rem;
        border-radius: 0.25rem;
        border: 0.0625rem solid #666;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
}

// AG Grid global styles
.ag-root-wrapper {
    width: 100%;
}

#info-banner-wrapper {
    width: calc(50% - 1rem);
    padding-left: 1rem;
    .mc-info-banner-wrapper .mc-info-banner-box p {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

.tiny-dropdown-list-items {
    .dropdown-list-item {
        height: 1.5rem;
        padding-top: 0rem;
        padding-bottom: 0rem;
    }
}

.form-item {
    width: 30rem;
    max-width: 30rem;
    @include breakpoint-mobile {
        width: calc(100% - 3rem);
    }
}

.ag-grid-and-header {
    .page-head-row {
        padding-top: 0.75rem;
        padding-bottom: 1rem;

        // for mobile
        @include breakpoint-mobile {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            h2 {
                font-size: 1.5rem;
                padding-left: 1rem;
            }
        }
        display: flex;
        justify-content: space-between;

        .page-header-buttons {
            display: flex;
            gap: 2rem;
            // for mobile
            @include breakpoint-mobile {
                gap: 1rem;
            }

            align-items: center;

            .add-new-remark-button-mobile {
                margin-right: 0.5rem;
            }
        }
    }

    .ag-grid-table-with-header-row {
        height: calc(100dvh - 172px);

        @include breakpoint-mobile {
            height: calc(100dvh - 230px);
        }
    }
}

.auth-title {
    text-align: center;
}
</style>

/* eslint-disable style/max-len */
/* eslint-disable vue/max-len */
import equipmentApi from '@/api/equipment'

import mappingClaims from './mapping/mapClaims'
import mappingOrderParts from './mapping/mapOrderParts'
import { createCommentForSpares, performQuery } from './utils'

import type {
    IClaimCreateData,
    IClaimListElement,
    IClaimPendingListElement,
    IClaimStatus,
    IColumnListObject,
    ICreateCommentForOrderPart,
    IMondayGroup,
    IMondayUpdate,
    IOrderingPartCreateData,
    IOrderingPartListElement,
    IOrderingPartPendingListElement,
    IOrderingPartStatus,
    ISupportCaseType,
} from '../interfaces/ISupport'
import type { UUIDv4 } from '@/modules/CORE/company/interfaces/IGeneric'
import type { IUser } from '@/modules/CORE/interfaces/IUser'

import { dateISOParser, todayISODate } from '@/utils'

//  This file contains methods to perform queries to Monday.com API
//  The queries are used to fetch and update data in the Monday.com database
//  Dev-board-id for "Ordering parts" is 7957843497, real board id is 273220535
//  Dev-board-id for "Claims" is 187579338, real board id is dependant on the facility. Use the facility "Best boat"
//  hence this is connected to the "Dev-board-id"

// development board id for "Ordering parts"
let orderPartBoardId = '7957843497'
let columnIdFacilityId = 'text_mkmt1zpd'

// choose real board id if host is production
const host = import.meta.env.VITE_APP_API_HOST as string
if (host === 'https://backend.m-link.no') {
    orderPartBoardId = '273220535'
    columnIdFacilityId = 'text_mknkejj6'
}

const claimGroups = `["duplicate_of_claims", "duplicate_of_claims_som_er_utb", "claims", "topics", "utbedret_claims_", "duplicate_of_claims_som_er_utb", "duplicate_of_corrected_claims83", "corrected_claims", "utbedret_claims", "claims_som_er_utbedret", "claims_corrected", "claims_som_er_utbedret_", "claims"
 ]`

const supportGroups = `["other_ongoing_tasks", "duplicate_of_claims7026", "duplicate_of_other_ongoing_tas", "other_ongoing_taskes", "other_ongoing_",
 "other_ongoing", "other_ongoing_issues", "other_ongoing_tasaks", "other"]`

const querymethods = {
    // Methods for claims
    fetchAllClaimsByMondayBoardId: async (boardId: string): Promise<IClaimListElement[]> => {
        const query = `
        {
            boards(ids: [${boardId}])
            {
                groups( ids:  ${claimGroups})
                {
                    items_page(limit: 500)
                    {
                        items
                        {
                            id
                            name
                            updated_at
                            column_values
                            {
                                id
                                text
                                column
                                {
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }`
        try {
            const response = await performQuery(query)

            if (response.data.data.boards.length === 0) {
                return []
            }

            const claimItems = response.data.data.boards[0].groups

            if (claimItems.length > 0) {
                return mappingClaims.mapNotPendingClaims(claimItems, 'claims')
            }

            return []
        } catch (error) {
            console.error(error)
            return []
        }
    },
    // Pending means if it has a status of "Waiting for customer"
    fetchPendingClaimsByMondayBoardId: async (boardId: string): Promise<IClaimPendingListElement[]> => {
        const query = `
        {
            boards(ids: [${boardId}])
            {
                groups(ids: ${claimGroups})
                {
                    items_page(limit: 500,query_params: {rules: [{column_id: "status", compare_value: [7], operator: any_of }]} )
                    {
                        items
                        {
                            id
                            name
                            updated_at
                            column_values
                            {
                                id
                                text
                                column
                                {
                                    title
                                }
                            }
                            updates
                            {
                                text_body,
                                body
                            }
                        }
                    }
                }
            }
        }`
        try {
            const response = await performQuery(query)
            const claimItems = response.data.data.boards[0].groups

            if (claimItems.length > 0) {
                return mappingClaims.mapPendingClaims(claimItems, 'claims')
            }

            return []
        } catch (error) {
            console.error(error)
            return []
        }
    },
    // Methods for support cases
    fetchAllSupportByMondayBoardId: async (boardId: string): Promise<IClaimListElement[]> => {
        const query = `
        {
            boards(ids: [${boardId}])
            {
                groups( ids:  ${supportGroups})
                {
                    items_page(limit: 500)
                    {
                        items
                        {
                            id
                            name
                            updated_at
                            column_values
                            {
                                id
                                text
                                column
                                {
                                    title
                                }
                            }
                        }
                    }
                }
            }
        }`
        try {
            const response = await performQuery(query)
            const claimItems = response.data.data.boards[0].groups

            if (claimItems.length > 0) {
                return mappingClaims.mapNotPendingClaims(claimItems, 'support')
            }

            return []
        } catch (error) {
            console.error(error)
            return []
        }
    },
    // Pending means if it has a status of "Waiting for customer"
    fetchPendingSupportByMondayBoardId: async (boardId: string): Promise<IClaimPendingListElement[]> => {
        const query = `
        {
            boards(ids: [${boardId}])
            {
                groups(ids: ${supportGroups})
                {
                    items_page(limit: 500,query_params: {rules: [{column_id: "status", compare_value: [7], operator: any_of }]} )
                    {
                        items
                        {
                            id
                            name
                            updated_at
                            column_values
                            {
                                id
                                text
                                column
                                {
                                    title
                                }
                            }
                            updates
                            {
                                text_body,
                                body
                            }
                        }
                    }
                }
            }
        }`
        try {
            const response = await performQuery(query)
            const claimItems = response.data.data.boards[0].groups

            if (claimItems.length > 0) {
                return mappingClaims.mapPendingClaims(claimItems, 'support')
            }

            return []
        } catch (error) {
            console.error(error)
            return []
        }
    },
    // Create a new claim
    createClaim: async (data: IClaimCreateData, type: ISupportCaseType) => {
        const groups = await querymethods.getMondayGroupList(data.boardId)
        let foundGroup
        if (type === 'support') {
            foundGroup = groups.find((group: IMondayGroup) => {
                return group.title.toLowerCase() === 'other ongoing tasks'
            })
        }
        if (type === 'claim') {
            foundGroup = groups.find((group: IMondayGroup) => {
                return group.title.toLowerCase() === 'claims'
            })
        }

        const groupId = foundGroup && foundGroup.id

        const columnsList: IColumnListObject[] = [
            {
                title: 'mLink Claim ID',
                dataField: 'claimId',
            },
            {
                title: 'Customer Priority',
                dataField: 'priority',
            },
            {
                title: 'SFI Codes',
                dataField: 'sfi',
            },
            {
                title: 'Date filed',
            },
            {
                title: 'Equipment',
                dataField: 'equipment',
            },
            {
                title: 'Related mLINK WO',
                dataField: 'workorder',
            },
        ]

        const mondayColumns = await querymethods.getMondayColumnsList(data.boardId)
        interface ColumnValues {
            [key: string]: any
        }
        const columnValues: ColumnValues = {}
        if (mondayColumns) {
            for (let i = 0; i < columnsList.length; i++) {
                const column: IColumnListObject = columnsList[i]
                const mondayColumn = mondayColumns.find((c: any) => {
                    return c.title === column.title
                })
                if (mondayColumn) {
                    if (column.title === 'Date filed') {
                        columnValues[mondayColumn.id] = {
                            date: todayISODate(),
                        }
                    } else if (column.title === 'Equipment') {
                        columnValues[mondayColumn.id] = data.equipment
                    } else if (column.title === 'SFI Codes') {
                        if (data.equipment.length > 0) {
                            try {
                                const uuidsForEquipment = data.equipment.split(';') as UUIDv4[]
                                const sfiResponse = await equipmentApi.getClassificationCodesStringByEquipmentIds(uuidsForEquipment)
                                columnValues[mondayColumn.id] = sfiResponse
                            } catch (error) {
                                console.error('Failed to fetch SFI codes for equipment', error)
                            }
                        }
                    } else if (column.dataField !== undefined) {
                        // @ts-expect-error-mondayColumn
                        columnValues[mondayColumn.id] = data[column.dataField] && data[column.dataField].toString()
                    }
                } else {
                    const newColumnId = await querymethods.createMondayColumn(data.boardId, column.title, 'text')
                    // @ts-expect-error-newColumnId
                    columnValues[newColumnId] = data[column.dataField] && data[column.dataField].toString()
                }
            }
        }
        const columnValuesText = JSON.stringify(columnValues).replace(/"/g, '\\"')
        if (groupId) return querymethods.createItemOnBoard(data.boardId, groupId, data.itemName, columnValuesText)
    },

    // Monday methods to find the correct compare_values for the "Status" column in Claims and Ordering parts
    // compare_values is an index for a given status such as "Waiting for customer" or "Working on it" that is needed to perform queries

    // Method to find the "compare_value" for the different statuses in the "Status" column in Claims
    // Check IClaimStatus for the different statuses
    findStatusCompareValueIndexByColumnNameClaims: async (boardId: string, statusName: IClaimStatus): Promise<string> => {
        const columnQuery = `
            {
                boards(ids: ${boardId})
                {
                    columns {
                        id
                        settings_str
                      }
                }
            }`
        try {
            const performColumnQuery = await performQuery(columnQuery)
            const columnValues = performColumnQuery.data.data.boards[0].columns.find((c: any) => c.id === 'status').settings_str
            // This will be a touple list with the first index being the "compare_value" and the second index being the "labels"
            const list = Object.entries(JSON.parse(`${columnValues}`).labels)
            const status_compare_value = list[list.findIndex((tuple) => tuple[1] === statusName)][0]
            return status_compare_value
        } catch (error) {
            console.error(`Failed to find the compare value for "claim". Param used:\n ${statusName}`, error)
            return ''
        }
    },
    // Order parts methods

    getPartsOrderingListByFacilityName: async (
        facilityName: string,
        facilityId: UUIDv4 | undefined,
        pendingOrderingparts?: boolean,
    ): Promise<IOrderingPartListElement[] | IOrderingPartPendingListElement[]> => {
        let query = ''

        if (pendingOrderingparts) {
            // Returnerer de som trenger aksept fra kunde
            /*
            {
                                    column_id: "text0",
                                    compare_value: ["${facilityName}"],
                                    operator: contains_text
                                },
                                {
                                    column_id: "text_mkmt1zpd",
                                    compare_value: ["${facilityId}"],
                                    operator: contains_text
                                },
             */
            query = `query
           {
               boards(ids: [${orderPartBoardId}]) {
                   groups(ids: ["topics"]) {
                       items_page(limit: 500, query_params: {
                            rules: [

                                {
                                        column_id: "${columnIdFacilityId}",
                                        compare_value: ["${facilityId}"],
                                        operator: contains_text
                                },
                                {
                                    column_id: "status",
                                    compare_value: [14],
                                    operator: any_of
                                },
                                {
                                    column_id: "check8",
                                    compare_value: [],
                                    operator: is_empty
                                }
                            ],
                            operator: and
                        })
                       {
                           items
                           {
                               id,
                               name,
                               updated_at,
                               column_values {
                                   text
                                   column
                                   {
                                       title
                                         id
                                   }
                               }
                               updates
                               {
                                   text_body,
                                   body
                               }
                           }

                       }
                   }
               }
           }`
        } else {
            // Querier tilbake alle bestillinger som er akseptert av kunde
            /*
            {
                                        column_id: "text0",
                                        compare_value: ["${facilityName}"],
                                        operator: contains_text
                                    },
                                    {
                                    column_id: "text_mkmt1zpd",
                                    compare_value: ["${facilityId}"],
                                    operator: contains_text
                                },
            */
            query = `query
            {
                boards(ids: [${orderPartBoardId}]) {
                    groups(ids: ["topics"]) {
                        items_page(limit: 500,
                            query_params:{
                                rules:
                                [

                                {
                                        column_id: "${columnIdFacilityId}",
                                        compare_value: ["${facilityId}"],
                                        operator: contains_text
                                    },
                                    {
                                        column_id: "status",
                                        compare_value: [14],
                                        operator: not_any_of
                                    }
                                ],
                                operator: and
                            })
                        {
                            items
                            {
                                id,
                                name,
                                updated_at,
                                column_values {
                                    text
                                    column
                                    {
                                        title
                                    }
                                }
                            }
                        }
                    }
                }
            }`
        }

        const result = await performQuery(query)
        if (result) {
            const response = result && result.data.data && result.data.data.boards[0] && result.data.data.boards[0].groups[0]
            if (response) {
                if (response.items_page.items.length > 0) {
                    if (pendingOrderingparts) {
                        return mappingOrderParts.mapPendingOrderParts(response.items_page.items)
                    } else {
                        return mappingOrderParts.mapNotPendingOrderParts(response.items_page.items)
                    }
                }
            }
        } else {
            console.error('Failer to receive ordering parts list')
        }
        return []
    },
    getOrderingPartsDetailByOrderPartId: async (orderPartId: string) => {
        const query = `query
                        {
                            items(ids: [${orderPartId}])
                            {
                                id,
                                name,
                                created_at,
                                updated_at,
                                column_values {
                                    id,
                                    text
                                    value
                                    column
                                    {
                                        title
                                    }
                                }
                                updates {
                                    id,
                                    body
                                }
                            }
                        }`
        try {
            const response = await performQuery(query)
            const data = response.data.data.items[0]
            return mappingOrderParts.mapDetailsOrderParts(data)
        } catch (error) {
            console.error('Failed to receive ordering parts list', error)
        }
    },

    acceptOrderingPart: async (orderPartId: string) => {
        const now = new Date()
        const formattedDate = now.toISOString()
        const confirmOrderMutation = `mutation {
                change_multiple_column_values(
                        board_id: ${orderPartBoardId},
                        item_id: ${orderPartId},
                        column_values:  "{\\"check8\\": {\\"checked\\":\\"true\\",\\"changed_at\\":\\"${formattedDate}\\"},\\"status\\":\\"Working on it\\"}",
                    ) {
                        id
                    }
                }`
        try {
            const response = await performQuery(confirmOrderMutation)
            if (response?.data) {
                return response.data.data.change_multiple_column_values.id
            }
        } catch (err) {
            console.warn('', err)
        }
    },
    // Create ordering part
    createOrderingPart: async (
        facilityName: string,
        facilityId: UUIDv4,
        companyName: string,
        order: IOrderingPartCreateData,
        user: IUser,
    ) => {
        const groupId = 'topics'
        const columnsList = [
            {
                title: 'Date',
                text: dateISOParser(new Date()),
            },
            {
                title: 'Kunde',
                text: companyName,
            },
            {
                title: 'Fasilitet',
                text: facilityName,
            },
            {
                title: 'Referanse kunde',
                text: order.customer_contact,
            },
            {
                title: 'Leveringsadresse',
                text: order.delivery_address,
            },
            {
                title: 'Fakturaadresse',
                text: order.invoice_address,
            },
            {
                title: 'facilityId',
                text: facilityId,
            },
        ]
        const mondayColumns = await querymethods.getMondayColumnsList(orderPartBoardId)
        const columnValues: any = {}
        if (mondayColumns) {
            for (let i = 0; i < columnsList.length; i++) {
                const column = columnsList[i]
                const mondayColumn = mondayColumns.find((c: any) => c.title === column.title)
                if (mondayColumn) {
                    columnValues[mondayColumn.id] = column.text
                }
            }
        }

        const columnValuesText = JSON.stringify(columnValues).replace(/"/g, '\\"')
        try {
            const mutation = `mutation {
                create_item (board_id: ${orderPartBoardId}, group_id: "${groupId}", item_name: "${order.title}", column_values: "${columnValuesText}") {
                    id
                }
            }`
            const response = await performQuery(mutation)
            if (response && response.data && response.data.data.create_item) {
                const boardItemId = response.data.data.create_item.id
                const commentCreateResponse = await createCommentForSpares(
                    {
                        itemId: boardItemId,
                        user,
                        email: order.customer_contact_email,
                        phone: order.customer_contact_mobile,
                        description: order.description,
                        attachments: order.attachments,
                    } as ICreateCommentForOrderPart,
                    facilityId,
                )
                if (commentCreateResponse) {
                    return { id: boardItemId }
                }
            }
        } catch (e) {
            console.error(e)
        }
        return null
    },

    // Method to find the "compare_value" for the different statuses in the "Status" column in Ordering parts
    // Check IOrderingPartStatus for the different statuses
    findStatusCompareValueIndexOrderPart: async (statusName: IOrderingPartStatus): Promise<string> => {
        const columnQuery = `
        {
            boards(ids: [${orderPartBoardId}])
            {
                columns {
                    id
                    settings_str
                }
            }
        }`
        try {
            const performColumnQuery = await performQuery(columnQuery)
            const columnValues = performColumnQuery.data.data.boards[0].columns.find((c: any) => c.id === 'status').settings_str
            // This will be a touple list with the first index being the "compare_value" and the second index being the "labels"
            const list = Object.entries(JSON.parse(`${columnValues}`).labels)
            const status_compare_value = list[list.findIndex((tuple) => tuple[1] === statusName)][0]
            return status_compare_value
        } catch (error) {
            console.error(`Failed to find the compare value for "order part. Param used:\n ${statusName}`, error)
            return ''
        }
    },
    // Generic methods that works on both "Claims" and "Ordering parts"
    createUpdate: async (itemId: any, body: any) => {
        const escapedText = body.replace(/"/g, '\\"')
        const query = `mutation {
            create_update (item_id: ${itemId}, body: "${escapedText}") {
                id
            }
        }`

        return performQuery(query).then((result) => {
            return result && result.data.data && result.data.data.create_update
        })
    },
    getMondayGroupList: async (boardId: string): Promise<IMondayGroup[]> => {
        const query = `
        query {
                boards(ids: ${boardId}) {
                  groups {
                    title,
                    id,
                  }
                }
              }`
        const graphQLquery = await performQuery(query)
            .then((result) => {
                return result.data.data && result.data.data.boards && result.data.data.boards[0] && result.data.data.boards[0].groups
            })
            .catch((error) => {
                console.error('something failed while fetching groups list', error)
                return false
            })

        return graphQLquery
    },
    getMondayColumnsList: async (boardId: string) => {
        const query = `query {
            boards(ids: ${boardId}) {
              columns {
                id,
                title
              }
            }
          }`
        return performQuery(query)
            .then((result) => {
                return result && result.data.data && result.data.data.boards[0].columns
            })
            .catch((error) => {
                console.error('something failed while fetching columns list', error)
            })
    },
    createMondayColumn: async (boardId: string, title: string, type: string) => {
        const query = `mutation {
        create_column (board_id: ${boardId}, title: "${title}", column_type: ${type}) {
          id
        }
      }`
        return performQuery(query)
            .then((result) => {
                return result && result.data.data && result.data.data.create_column && result.data.data.create_column.id
            })
            .catch((error) => {
                console.error('something failed while creating new column', error)
            })
    },
    createItemOnBoard: async (boardId: string, groupId: string, itemName: string, columnValues: string) => {
        const query = `mutation {
        create_item (board_id: ${boardId}, group_id: "${groupId}", item_name: "${itemName}", column_values: "${columnValues}") {
          id
        }
      }`

        return await performQuery(query)
            .then((result) => {
                return result && result.data.data && result.data.data.create_item
            })
            .catch((error) => {
                console.error('something failed while creating item', error)
            })
    },
    deleteMondayUpdate: async (updateId: string): Promise<string | null> => {
        const mutation = `mutation {
            delete_update (id: ${updateId}) {
                id
            }
        }`

        try {
            const response = await performQuery(mutation)
            return response?.data?.data?.delete_update?.id || null
        } catch (error) {
            console.error('Failed to delete comment (update) from Monday.com', error)
            return null
        }
    },
    getUpdatesForMondayTopicByTopicId: async (topicId: string): Promise<IMondayUpdate[] | undefined> => {
        const query = `query
        {
            items(ids: [${topicId}])
            {
                updates(limit: 100) {
                    id,
                    body,
                    created_at,
                    text_body,
                    creator {
                        email,
                        name
                    }
                }
            }
        }`
        try {
            const response = await performQuery(query)
            return response.data.data.items[0].updates
        } catch (error) {
            console.error('Failed to receive updates for topic', error)
        }
    },
    updateSingleColumnValue: async (boardId: string, itemId: string, columnId: string, value: string) => {
        const query = `mutation {
            change_simple_column_value (board_id: ${boardId}, item_id: ${itemId}, column_id: "${columnId}", value: "${value}") {
                id
            }
        }`
        return performQuery(query).then((result) => {
            return result && result.data.data && result.data.data.change_column_value
        })
    },
}

export default querymethods

<template>
    <div class="menu-button">
        <div v-if="tinyArrowToIndicateSubMenu" class="tiny-arrow">
            <MCIcon icon="chevron-right" :size="16" />
        </div>
        <div
            class="icon-wrapper"
            role="button"
            :class="{ active: props.active }"
            tabindex="0"
            @click="emit('action')"
            @keydown.space="emit('action')"
            @keydown.enter="emit('action')"
        >
            <MCIcon ref="iconRef" :icon="icon" :size="26" :class="{ btnPulsate: wiggleButton }" />
            <div v-if="props.notificationsCount > 0" class="notification-badge">
                {{ props.notificationsCount }}
            </div>
        </div>
        <p class="body-small no-line-break" :class="{ bigger: props.biggerText }" :style="customTextStyle ? customTextStyle : ''">
            {{ text }}
        </p>
    </div>
</template>

<script setup lang="ts">
import { defineEmits, defineProps, onMounted, ref } from 'vue'

import type { PropType } from 'vue'

// props
const props = defineProps({
    text: {
        type: String as PropType<string>,
        required: true,
    },
    icon: {
        type: String as PropType<string>,
        required: true,
    },
    active: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    biggerText: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    notificationsCount: {
        type: Number as PropType<number>,
        required: false,
        default: 0,
    },
    customTextStyle: {
        type: String as PropType<string>,
        required: false,
    },
    tinyArrowToIndicateSubMenu: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    wiggleButton: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
})

const emit = defineEmits(['action', 'buttonWiggled'])
const iconRef = ref(null)

onMounted(() => {
    const animated = iconRef.value?.$el
    if (animated) {
        animated.addEventListener('animationend', () => {
            // Emit a signal to the parent component that the button has finished wiggling
            emit('buttonWiggled')
        })
    }
})
</script>

<style lang="scss" scoped>
@import '@/scss/colors.scss';

@keyframes btn-pulse {
    0% {
        transform: scale(1) rotate(0deg);
    }
    25% {
        transform: scale(1.2) rotate(35deg);
    }
    50% {
        transform: scale(1.3) rotate(0deg);
    }
    75% {
        transform: scale(1.1) rotate(-35deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}

.btnPulsate {
    animation: btn-pulse 3s linear 0s 4;
}

.menu-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 3.875rem;
    cursor: pointer;
    position: relative;

    .tiny-arrow {
        position: absolute;
        top: 0.3rem;
        right: 0px;
        z-index: 99;
    }

    .icon-wrapper {
        position: relative;
        display: flex;
        width: 3.875rem;
        justify-content: center;
        align-items: center;
        border-radius: 0.9375rem;
        &.active {
            background: $interactive-lightest;
        }
        .notification-badge {
            position: absolute;
            top: -0.3125rem;
            right: 0.75rem;
            background: $danger-main;
            color: white;
            border-radius: 50%;
            padding: 0.125rem 0.3125rem;
            font-size: 0.625rem;
            font-weight: bold;
        }
    }
    .body-small {
        color: #22272a;
        font-size: 0.75rem;
        letter-spacing: 0.0075rem;
        &.bigger {
            font-size: 0.875rem;
            letter-spacing: 0.0088rem;
            margin-top: 0.125rem;
        }
    }

    &:hover {
        .icon-wrapper {
            background-color: $interactive-lightest;
        }
    }
    &:active {
        .icon-wrapper {
            background-color: $interactive-lighter;
        }
    }
}

.no-line-break {
    white-space: nowrap;
}
</style>

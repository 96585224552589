// https://animate.style/
import 'animate.css'
// Read the docs for more information: https://animate.style/#documentation

// example usage for v-animate directive
// <div v-if="condition" v-animate.slideInRight>...</div>
// <div v-if="condition" v-animate.fadeIn>...</div> // <-- fadeIn is the animation name (enter animation only, leave will be instant)
// <div v-if="condition" v-animate.fadeIn.fadeOut>...</div> // <-- fadeIn and fadeOut are the animation names (enter + leave animations)
// enter animation is applied on component mount
// leave animation is applied on component unmount

import type { DirectiveBinding } from 'vue'

interface IBinding extends DirectiveBinding {
    // add any additional properties if needed
}

/**
 * Vue directive for applying animations using Animate.css.
 *
 * This directive allows animations to be applied to Vue components dynamically.
 * It supports two ways of specifying animations:
 * 1. Using arguments (e.g., v-animate:[animationType])
 * 2. Using modifiers (e.g., v-animate.fadeIn.fadeOut)
 *
 * The directive adds the 'animate__animated' class from Animate.css and
 * the specific animation classes based on the provided arguments or modifiers.
 * It supports separate enter and leave animations.
 *
 * Usage:
 * - As an argument: v-animate:[animationType]
 *   Where animationType is a computed property returning strings like 'fadeInUp.fadeOutDown'
 *
 * - As modifiers: v-animate.fadeInUp.fadeOutDown
 *   Each modifier represents an animation class from Animate.css. The first is for entering, and the second (if provided) is for leaving.
 *
 * The directive clones the element to apply the leave animation, ensuring that the original element is hidden to prevent flickering.
 */
const animateDirective = {
    beforeMount(el: HTMLElement, binding: IBinding) {
        const animationNamePassedAsArgs = binding.arg
        let enterAnimation = ''
        let leaveAnimation = ''

        if (animationNamePassedAsArgs) {
            // Handling the case where animation names are passed as an argument (e.g. v-animate:[animationName])
            // ;[enterAnimation, leaveAnimation] = animationNamePassedAsArgs.split('.')
            enterAnimation = animationNamePassedAsArgs.split('.')[0]
            if (animationNamePassedAsArgs.split('.').length > 1) leaveAnimation = animationNamePassedAsArgs.split('.')[1] // if there are two args, the second one is the leave animation
        } else if (binding.modifiers) {
            // Handling the case where animation names are passed as modifiers (e.g. v-animate.fadeIn.fadeOut)
            const animations = Object.keys(binding.modifiers)
            enterAnimation = animations[0]
            if (animations.length === 2) leaveAnimation = animations[1] // if there are two modifiers, the second one is the leave animation
        }
        // Add the 'animate__animated' class to enable Animate.css animations
        el.classList.add('animate__animated')

        // Apply the enter animation class if it exists
        if (enterAnimation) {
            el.classList.add(`animate__${enterAnimation}`)
            el.dataset.enterAnimation = enterAnimation
        }

        // Store the leave animation in dataset for later use
        if (leaveAnimation) {
            el.dataset.leaveAnimation = leaveAnimation
        }
    },

    beforeUnmount(el: HTMLElement) {
        const leaveAnimation = el.dataset.leaveAnimation

        if (leaveAnimation) {
            // Clone the element
            const clonedEl = el.cloneNode(true) as HTMLElement

            // Append the clone to the parent of the original element
            el.parentNode?.appendChild(clonedEl)

            // Remove the enter animation and apply the leave animation
            clonedEl.classList.remove(`animate__${el.dataset.enterAnimation}`)
            clonedEl.classList.add(`animate__${leaveAnimation}`)

            // Delay the removal of the element until after the leave animation is completed
            clonedEl.addEventListener('animationend', () => {
                clonedEl.remove()
            })

            // Hide the original element immediately to avoid flickering
            el.style.display = 'none'
        }
    },
}

// Export the directive
export default animateDirective

<template>
    <div
        class="search-result-wrapper"
        tabindex="0"
        @click="emit('action')"
        @keydown.enter="emit('action')"
        @keydown.space="emit('action')"
        @keydown.esc="emit('escape')"
        @keydown.arrow-up="emit('previous')"
        @keydown.arrow-down="emit('next')"
    >
        <div class="search-result-container">
            <div class="search-result-container-inner">
                <p class="search-result-blue-header">
                    {{ elementInfo.label }}
                </p>
                <p v-if="elementInfo.secondLabel.length > 0" class="body-body">
                    {{ elementInfo.secondLabel }}
                </p>
                <div v-if="elementInfo.footerLabels" class="search-result-footer">
                    <div
                        v-for="(label, l) in elementInfo.footerLabels"
                        :key="label"
                        class="search-result-footer-element"
                        :style="l == 0 ? 'padding: 0' : ''"
                    >
                        <p class="body-small">{{ `${label}` }} {{ `${l == elementInfo.footerLabels.length - 1 ? '' : '|'}` }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ISearchResult } from '@/modules/CORE/search/interfaces/ISearch'
import type { PropType } from 'vue'

const props = defineProps({
    elementInfo: {
        type: Object as PropType<ISearchResult>,
        required: true,
    },
})

const emit = defineEmits(['escape', 'action', 'previous', 'next'])
</script>

<style lang="scss">
.search-result-wrapper {
    display: flex;
    padding: 1.5rem;
    flex-direction: column;
    align-items: flex-start;
    gap: 1.5rem;
    align-self: stretch;
    border-radius: 0.5rem 0.5rem 0rem 0rem;
    background: #fff;
    .search-result-container {
        display: flex;
        align-items: center;
        gap: 0.375rem;
        align-self: stretch;
        .search-result-container-inner {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.25rem;
            flex: 1 0 0;
            .search-result-blue-header {
                color: var(--mLINK-interactive-base, #006ece);
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 800;
                line-height: 150%; /* 1.6875rem */
                letter-spacing: -0.00225rem;
            }
            .search-result-footer {
                flex-direction: row;
                display: flex;
                align-items: flex-start;
                .search-result-footer-element {
                    display: flex;
                    padding: 0rem 0.625rem;
                    align-items: flex-start;
                    gap: 0.625rem;
                }
            }
        }
    }
}
</style>
